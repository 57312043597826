import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";

export const CreatePurchasePlan = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "99999999" ,defaultMessage:"Purchase Plan"})
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [branch, setBranch] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [qty, setQty] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const [listMaterial, setListMaterial] = useState([]);

  const [listUnit, setListUnit] = useState([]);
  const [unit, setUnit] = useState(null)
  // const [listSelectedData, setListSelectedData] = useState([]);

  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    try {
      const res = await axios({
        method: "get",
        url: `${baseURL}/inventory/material?search=&page=1&limit=1000`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data && res.data.data) {
        setListMaterial(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    gettingShop();
    gettingItem();
    getListMaterial();
  }, []);

  useEffect(() => {
    console.log("xxxxxxxlistMaterial::::::", listMaterial)
  }, [listMaterial]);

  const handleChangeItem = (event, values) => {
    setItem(values);
    if (values) {
      // แปลง major_unit และ minor_unit จาก item ที่เลือก
      const units = [
        { id: values.major_unit_id, name: values.major_unit_name },
        { id: values.minor_unit_id, name: values.minor_unit_name },
      ];
      setListUnit(units);
      setUnit(null); // ล้างค่า unit เมื่อเปลี่ยน item
    } else {
      setListUnit([]);
      setUnit(null);
    }
  };

  const handleChangeUnit = (event, values) => {
    // if (values) {
    //   setUnit(values);
    // } else {
    //   setUnit(null);
    // }
    setUnit(values); // Store the entire unit object
  };

  const columns = [
    {
      dataField: "material_id",
      text: "ID",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "unit_name",
      text: "Unit",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row) => {
        return (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => deleteItem(row.id)}
          >
            {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
          </button>
        );
      },
    },
  ];

  const deleteItem = (id) => {
    setListData((prevData) => {
      const updatedList = prevData.filter((item) => item.id !== id);
      console.log("Updated List After Deletion:", updatedList);
      return updatedList; // Return the updated list directly
    });
  };
  

  const gettingShop = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setShopList(res.data.data);
    });
  };

  const gettingItem = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup/item`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleAdd = () => {
    if (!item || !qty || !unit) {
      Swal.fire("Error", "Please select an item, quantity, and unit before adding.", "error");
      return;
    }

    const newItem = {
      id: maxIndex + 1,
      name: item.name,
      material_id: item.id,
      quantity: qty,
      unit_id: unit.id,
      unit_name: unit.name
    };

    setListData((prevData) => [...prevData, newItem]);
    setMaxIndex(maxIndex + 1);
    setQty("");
    setItem(null);
    setUnit("");
    setIsAdd(true);
  };

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    let now = new Date() ////// moment ?!
    var events = {
        name:description,
      orders: listData,
  }

    let apiUrls = `${baseURL}/purchase/plan`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Plan success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Plan . !! " + err,
          "error"
        );
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "99999999999",
            defaultMessage: "Create Purchase Plan",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "99999999" ,defaultMessage:"Name" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                onChange={(table_name) => setDescription(table_name.target.value)}
              />
            </div>
          </div>


          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              {/* <div> */}
                <Autocomplete
                  id="grouped-unit"
                  options={listMaterial}
                  // freeSolo
                  disableClearable
                // getOptionLabel={(option) => `${option.name} - ${option.major_unit_name}`}
                getOptionLabel={(option) => `${option.name}`}
                value={item} 
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    // borderWidth: "0",
                  }}
                  onChange={handleChangeItem}
                // onChange={(event, newValue) => setItem(newValue)}
                  renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                />
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={qty}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  // borderWidth: "0",
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  // อนุญาตเฉพาะตัวเลข หรือเว้นว่าง
                  setQty(value === "" ? "" : parseFloat(value));
                }}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113218",
                  defaultMessage: "Unit",
                })}
                :{" "}
              </p>
              <Autocomplete
                id="grouped-unit"
                options={listUnit}
                disableClearable
                getOptionLabel={(option) => `${option.name}`}
                value={unit ? { name: unit.name } : null}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={handleChangeUnit}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-inline d-flex justify-content-center"
            >
              <button className="btn btn-primary" onClick={handleAdd}>
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>
          </div>

        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div className="container mt-3">
            <div className="form-inline d-flex justify-content-center"
            >
              <Link to="/PurchasePlan">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "107016",
                    defaultMessage: "Cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => handleSend()}>
                {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};