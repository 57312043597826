import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const EditBuffet = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles();
    suhbeader.setTitle("MenuBuffet"
        // intl.formatMessage({ id: "102007", defaultMessage: "Shop/Branch" })
    );

    const history = useHistory();
    const [limitPerOrderFlag, setLimitPerOrderFlag] = useState(false)
    const [limitPerOrder, setLimitPerOrder] = useState(null)
    const AlertError = (message) => {
        Swal.fire("EditShop!", message, "error");
    };
    const onsubmit = async () => {
        let body
        console.log("firlimitPerOrder ->", limitPerOrder)
        console.log("limitperorderflag ->", limitPerOrderFlag)
        if (limitPerOrderFlag) {
            if (!limitPerOrder) {
                Swal.fire("Item limit per order is required.");
                return
            }
            body = {
                limit_qty: limitPerOrder,
            }
        } else {
            body = { limit_qty: null }
        }

        let apiUrls = `${baseURL}/management/buffet_option`;
        axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            data: body,
        })
            .then(async (res) => {
                Swal.fire("Save !", "Save Payment success", "success");
                history.goBack();
            })
            .catch((err) => {
                Swal.fire("Save !", "Your can not save payment. !! " + err, "error");
            });

    };

    useEffect(() => {
        getBuffetOption();
    }, []);


    const getBuffetOption = async () => {
        try {
            const response = await axios({
                method: "get",
                url: `${baseURL}/management/buffet_option`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            console.log("response.data ->", response.data);

            const buffetData = response.data.data.buffetOption;
            console.log("buffetData ->", buffetData)
            const firstItem = buffetData[0];
            if (firstItem.limit_qty !== undefined && firstItem.limit_qty !== null) {
                setLimitPerOrderFlag(true);
                setLimitPerOrder(firstItem.limit_qty);
            } else {
                setLimitPerOrderFlag(false);
                setLimitPerOrder(null);
            }
        } catch (error) {
            console.error("Error fetching buffet option:", error);
            setLimitPerOrderFlag(false);
            setLimitPerOrder(0);
        }
    };


    return (
        <Card>
            <CardHeader
                title={intl.formatMessage({
                    id: "113508",
                    defaultMessage: "Edit Buffet",
                })}
            >
            </CardHeader>
            <CardBody>
                <div className="container">

                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>
                        {intl.formatMessage({ id: "113510", defaultMessage: "Buffet Setting" })}
                        </div>
                        <div
                            className="form-inline"
                        >
                            <div style={{ display: "flex", alignItems: "start", gap: "15px" }}>
                                <span style={{ marginBottom: "0px", marginTop: "10px" }}>
                                    {intl.formatMessage({ id: "113509", defaultMessage: "Set Limit per Item" })}
                                </span>
                                <div>
                                <Switch
                                    checked={limitPerOrderFlag}
                                    onChange={() => setLimitPerOrderFlag(!limitPerOrderFlag)}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                {limitPerOrderFlag && (
                                    <div style={{ marginBottom: "10px" }}>
                                        <input
                                            id="limit-per-order-input"
                                            type="number"
                                            value={limitPerOrder}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value, 10);
                                                setLimitPerOrder(!isNaN(value) ? value : "");
                                            }}
                                            style={{
                                                padding: "5px",
                                                borderRadius: "5px",
                                                marginLeft: "10px",
                                                border: "1px solid #ccc",
                                            }}
                                        />
                                    </div>
                                )}
                                </div>

                            </div>
                        </div>

                    </div>





                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>


                        <Link to="/MenuBuffet">
                            <button type="button" className="btn btn-secondary wbt">
                                {intl.formatMessage({ id: "108011", defaultMessage: "cancel" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => onsubmit()}>
                            {intl.formatMessage({ id: "108032", defaultMessage: "Save" })}
                            {/* {intl.formatMessage({ id: "108012", defaultMessage: "record" })} */}
                        </button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
