import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from "react-intl";
import { useSubheader } from '../../../../_metronic/layout';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const EditWasteReport = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    suhbeader.setTitle(
        intl.formatMessage({ id: "99999999", defaultMessage: "Waste Report" })
    );
    const history = useHistory();

    const [state, setState] = useState(true);

    const [amount, setAmount] = useState(props.location.state.row.amount);
    const [name, setName] = useState(props.location.state.row.item);
    const [unit, setUnit] = useState(props.location.state.row.unit);
    const [unitType, setUnitType] = useState(""); // major/minor
    const [units, setUnits] = useState([]); // Dropdown options
    const [convert, setConvert] = useState(1);
    const [reason, setReason] = useState(props.location.state.row.reason);

    useEffect(() => {
        const fetchRejectRecord = async () => {
            try {
                const { data } = await axios.get(
                    `${baseURL}/inventory/wastereport/${props.location.state.row.id}`,
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );

                const { unitConvertData, materialData, rejectRecord } = data.data;

                const options = [
                    { id: unitConvertData.main_unit.id, label: unitConvertData.main_unit.name, type: "major" },
                    { id: unitConvertData.sub_unit.id, label: unitConvertData.sub_unit.name, type: "minor" },
                ];

                setUnits(options);
                setConvert(unitConvertData.qty_out_used);

                const initialUnit =
                    rejectRecord.unit_type === "major"
                        ? unitConvertData.main_unit
                        : unitConvertData.sub_unit;

                if (initialUnit) {
                    setUnit(initialUnit.id);
                    setUnitType(rejectRecord.unit_type);
                }
            } catch (error) {
                console.error("Failed to fetch rejectRecord:", error);
            }
        };

        fetchRejectRecord();
    }, [authToken, props.location.state.row.id]);

    const handleUnitChange = (event, option) => {
        if (option) {
            setUnit(option.id);
            setUnitType(option.type);
        }
    };

    const handleSave = async () => {
        if (!amount || !reason || !unitType) {
            Swal.fire("Error", "Please fill all required fields.", "error");
            return;
        }

        try {
            const result = await Swal.fire({
                title: "",
                text: "Confirm update waste report",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
                preConfirm: async () => {
                    try {
                        await axios.put(
                            `${baseURL}/inventory/wastereport`,
                            {
                                id: props.location.state.row.id,
                                quantity: amount,
                                reason,
                                unit_type: unitType,
                            },
                            {
                                headers: {
                                    Accept: "application/json",
                                    Authorization: `Bearer ${authToken}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error("Failed to update waste report:", error);
                        throw new Error("Failed to update waste report.");
                    }
                },
            });

            if (result.isConfirmed) {
                Swal.fire("Success", "Waste report updated successfully!", "success");
                history.push("/WasteReport");
            }
        } catch (error) {
            Swal.fire("Error", error.message || "An unexpected error occurred.", "error");
            console.error("Error:", error);
        }
    };

    return (
        <Card>
            <CardHeader
                title={intl.formatMessage({
                    id: "99999999999",
                    defaultMessage: "Edit Waste Report",
                })}
            ></CardHeader>
            <CardBody>
                <div className="container">
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "99999999", defaultMessage: "Name" })}:{" "}
                        </p>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {name}
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({
                                id: "113235",
                                defaultMessage: "Unit",
                            })}
                            <span style={{ color: "red" }}>*</span>:
                        </p>
                        <Autocomplete
                            options={units}
                            getOptionLabel={(option) => option.label}
                            onChange={handleUnitChange}
                            value={units.find((u) => u.id === unit) || null}
                            style={{
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </div>
                </div>
                <div className="container">
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({
                                id: "999999999999",
                                defaultMessage: "Convert",
                            })}{" "}
                            :{" "}
                        </p>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {unitType == "major" ? `1:${convert}` : `${convert}:1`}
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "113221", defaultMessage: "Quantity" })}
                            <span style={{ color: "red" }}>*</span>:
                        </p>
                        <input
                            type='number'
                            name="amount"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                </div>
                <div className="container">
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({
                                id: "113224",
                                defaultMessage: "Reason",
                            })}
                            <span style={{ color: "red" }}>*</span>:{" "}
                        </p>
                        <textarea
                            style={{
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            rows={4}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </div>
                </div>
                <div className="container">
                    <div className="form-inline d-flex justify-content-center">
                        <Link to="/WasteReport">
                            <button type="button" className="btn btn-secondary wbt">
                                {intl.formatMessage({
                                    id: "107016",
                                    defaultMessage: "Cancel",
                                })}
                            </button>
                        </Link>
                        <button className="btn btn-primary"
                            onClick={() => handleSave()}
                        >
                            {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
                        </button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}