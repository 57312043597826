import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import Swal from "sweetalert2";

export const generatePdfRequisition = async (data, branchData, listData, grandQtyData, grandTotalData) => {
    console.log("data---------->", data)
    console.log("branchdata---------->", branchData)
    console.log("listdata---------->", listData)
    console.log("grandQtyData---------->", grandQtyData)
    console.log("grandTotalData---------->", grandTotalData)
    const date = new Date();
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options);

    // const sum_total = listData.reduce((data, item) => {
    //     return data + item.price_per_unit;
    // }, 0);
    // const sum_qty = listData.reduce((data, item) => {
    //     return data + item.quantity;
    // }, 0);

    // const sum_total = (listData || []).reduce((acc, item) => acc + (parseFloat(item.price_per_unit || "0") || 0), 0);
    // const sum_qty = (listData || []).reduce((acc, item) => acc + (parseFloat(item.quantity || "0") || 0), 0);
    // const sum_qty = listData[0]?.grand_qty || 0;
    // const sum_total = listData[0]?.grand_total || 0; 
    const sum_qty = grandQtyData;
    const sum_total = grandTotalData; 

    const transformedData = listData.map((item, index) => {
        return {
            ...item,
            number: index + 1
        };
    });

    const doc = new jsPDF();
    let yPosition = 110;
    let yPositionEnd;
    let lineText = "............................................"
    doc.setFont('SukhumvitSet-Medium');

    doc.setFontSize(10);
    doc.text(`ชื่อบริษัท : ${branchData[0]?.shop_name}`, 25, 20);
    doc.text(`สาขา : ${branchData[0]?.branch_name}`, 80, 20);
    doc.text(`ที่อยู่ : ${branchData[0]?.address}`, 25, 25);
    doc.text(`เลขประจำตัวผู้เสียภาษี : ${branchData[0]?.tax_id}`, 25, 30);

    doc.setFontSize(20);
    doc.text(`ใบขอซื้อ: Purchase Requisition (PR)`, 60, 50);

    doc.setFontSize(10);
    doc.text(`เลขที่ : ${data[0]?.pr_no}`, 150, 60);
    doc.text(`วันที่ : ${formattedDate}`, 150, 65);

    doc.text(`แผนกที่ขอซื้อ : ${data[0]?.name_th}`, 25, 60);
    doc.text(`ผู้ขอซื้อ : ${data[0]?.first_name}`, 25, 65);
    doc.text(`วันที่ต้องการใช้ : ${""}`, 25, 70);
    doc.text(`หมายเหตุจุดประสงค์ : ${data[0]?.description}`, 25, 75);

    doc.setFont('SukhumvitSet-Medium');
    const columns = [
        { header: 'ลำดับ', dataKey: 'no' },
        { header: 'รายการ', dataKey: 'listData_name' },
        { header: 'จำนวน', dataKey: 'qty' },
        { header: 'หน่วย', dataKey: 'unit_name' },
        { header: 'ราคาต่อหน่วย', dataKey: 'unit_price' },
    ];

    const rows = transformedData.map(item => ({
        no: item.number,
        listData_name: item.name,
        qty: item.quantity,
        unit_name: item.unit_name,
        unit_price: parseFloat(item.price_per_unit).toFixed(2),
    }));
    const rowCount = rows.length;
    yPositionEnd = yPosition + ((rowCount + 1) * 10)

    // const columnWidths = [30, 75, 40, 40];
    const columnWidths = [15, 75, 15, 25, 30];

    // แก้ sum_qty กับ sum_total เป็น data.grand_qty กับ data.grand_total
    const table2Row = ["รวม", sum_qty, parseFloat(sum_total).toFixed(2)];

    try {
        doc.autoTable({
            startY: yPosition,
            margin: { left: 25, right: 25 },

            head: [columns.map(col => col.header)],
            body: rows.map(row => Object.values(row)),
            theme: 'grid',
            headStyles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 12,
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
            },
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                total: { halign: 'right' }, // Align 'จำนวนเงิน' column to the right
                0: { cellWidth: columnWidths[0], halign: 'center' },
                1: { cellWidth: columnWidths[1], halign: 'left' },
                2: { cellWidth: columnWidths[2], halign: 'center' },
                3: { cellWidth: columnWidths[3], halign: 'right' },
                4: { cellWidth: columnWidths[4], halign: 'right' },
            },
        });

        const finalY = doc.lastAutoTable.finalY;
        doc.autoTable({
            margin: { left: 25, right: 25 },
            theme: 'grid',
            head: [],
            body: [table2Row],
            startY: finalY,
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0] + columnWidths[1], halign: 'right' }, // Merging two columns
                1: { cellWidth: columnWidths[2], halign: 'center' },
                2: { cellWidth: columnWidths[3] + columnWidths[4], halign: 'right' },
            },
        });

        // doc.text(`${lineText}`, 10, 260)
        // doc.text(`(${lineText})`, 10, 270)
        // doc.text(`ผู้จัดทำ`, 20, 275)
        // doc.text(`วันที่`, 10, 280)
        // doc.text(`${lineText}`, 15, 280)
        doc.text(`${lineText}`, 30, 260)
        doc.text(`(${lineText})`, 30, 270)
        doc.text(`ผู้จัดทำ`, 45, 275)
        doc.text(`วันที่`, 25, 282)
        doc.text(`${lineText}`, 32, 282)

        doc.text(`${lineText}`, 90, 260)
        doc.text(`(${lineText})`, 90, 270)
        doc.text(`ผู้ตรวจสอบ`, 100, 275)
        doc.text(`วันที่`, 85, 282)
        doc.text(`${lineText}`, 92, 282)

        doc.text(`${lineText}`, 150, 260)
        doc.text(`(${lineText})`, 150, 270)
        doc.text(`ผู้อนุมัติ`, 165, 275)
        doc.text(`วันที่`, 145, 282)
        doc.text(`${lineText}`, 152, 282)

        const filename = `${data[0]?.pr_no}.pdf`;
        doc.save(filename);
    } catch (error) {
        console.error('Error while generating PDF:', error);
        Swal.fire(`Error while generating PDF: ${error.message}`)
    }
}

