import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import { temp_store } from "../../../modules/function/temp_store.js"

export const EditPurchaseOrder = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113384" ,defaultMessage:"Purchase Order"})
  );
  const history = useHistory();
  const [listData, setListData] = useState([]);
  const [description, setDescription] = useState('')
  const [supplier, setSupplier] = useState('')
  const [tel, setTel] = useState('')
  const [location, setLocation] = useState('')
  const [tax_id, setTax] = useState('')
  const [condition, setCondition] = useState('')

  // const gettingData = async () => {
  //   let allData = [];
  //   await axios({
  //     method: "get",
  //     url: `${baseURL}/purchase/po/${temp_store(props).location.state.row.id}`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   }).then((res) => {
  //     console.log(res.data.data);
  //     setDescription(res.data?.data?.pr[0]?.description)
  //     setListData(res.data?.data?.list)
  //   });
  // };

  const gettingData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/purchase/po/${temp_store(props).location.state.row.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log('response.data', response.data)
      console.log('response.data.data', response.data.data)
      if (response.data && response.data.data) {
        console.log('**** response.data.data ****', response.data.data)
        setDescription(response.data.data.pr[0].description || "");
        setListData(response.data.data.list || []);
      } else {
        console.error("No data received or invalid format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    gettingData()
  }, []);

  useEffect(() => {
    console.log('**** description ****', description)
    console.log('**** listData ****', listData)

  }, [description, listData])


  const columns = [
    {
      dataField: "item_id",
      text: "ID",
    },

    {
      dataField: "name",
      text: "item name",
    },

    {
      dataField: "qty",
      text: "quantity",
    },

    {
      dataField: "unit_name",
      text: "unit name",
    },

    {
      dataField: "price_per_unit",
      text: "price per unit",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        console.log(id,row,rowIndex)
        return (
          <div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => reject(row.item_id,rowIndex)}
            >
              {intl.formatMessage({ id: "113388", defaultMessage: "Reject" })}
            </button>
          </div>
        );
      },
    },
  ];

  const reject = async(item_id,rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Item will be excluded from Purchase order!",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Reject",
      cancelButtonText: "Cancel",
    }).then((result) => {
        if (result.value) {
          axios({
            method: "get",
            url: `${baseURL}/purchase/item/reject/${item_id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }).then((res) => {
            deleteItem(rowIndex)
          });
        } 
    });

  }

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }
  

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };

  /* const handleApprove = async () => {
    var events = {
        supplier: supplier,
        location: location,
        tax_id: tax_id,
        condition: condition == '' ? '' : condition,
        tel:tel,
        vat:7
    }

    let apiUrls = `${baseURL}/purchase/po/approve/${temp_store(props).location.state.row.id}`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
    .then(async (res) => {
      Swal.fire("Save !", "PO Approved", "success");
      history.goBack();
    })
    .catch((err) => {
      Swal.fire(
        "Created !",
        "Your can not approve PO. !! " + err,
        "error"
      );
    });
  } */

  // const handleApprove = async () => {

  //   if (supplier === undefined || supplier === "") {
  //       return AlertError("Please insert Supplier !!");
  //   }
  //   if (location === undefined || location === "") {
  //       return AlertError("Please insert Location !!");
  //   }
  //   if (tax_id === undefined || tax_id === "") {
  //       return AlertError("Please insert Tax ID !!");
  //   }
  //   if (tel === undefined || tel === "") {
  //       return AlertError("Please insert Phone number !!");
  //   }
  //   var events = {
  //     supplier: supplier,
  //     location: location,
  //     tax_id: tax_id,
  //     condition: condition == '' ? '' : condition,
  //     tel:tel,
  //     vat:7
  //   }

  //   let apiUrls = `${baseURL}/purchase/po/approve/${temp_store(props).location.state.row.id}`;
  //   /* axios({
  //     method: "put",
  //     url: apiUrls,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //     data: events,
  //   })
  //   .then(async (res) => {
  //     Swal.fire("Save !", "Purchase order aprrove", "success");
  //     history.goBack();
  //   })
  //   .catch((err) => {
  //     Swal.fire(
  //       "Created !",
  //       "Your can not approve purchase order. !! " + err,
  //       "error"
  //     );
  //   }); */
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Purchase order will be approve.",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonText: "Approve",
  //     cancelButtonText: "Cancel",
  //   }).then((result) => {
  //       if (result.value) {
  //         axios({
  //           method: "put",
  //           url: apiUrls,
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //           data: events,
  //         })
  //         .then(async (res) => {
  //           Swal.fire("Save !", "Purchase order aprrove", "success");
  //           history.goBack();
  //         })
  //         .catch((err) => {
  //           Swal.fire(
  //             "Created !",
  //             "Your can not approve purchase order. !! " + err,
  //             "error"
  //           );
  //         });

  //       } 
  //   });
  // };

  const validateLocation = (location) => {
    if (!location || location.trim().length < 3 || location.trim().length > 100) return false;
    return /^[a-zA-Z0-9ก-ฮ\s\-.,]+$/.test(location);
  };

  const validateThaiTaxID = (taxID) => /^\d{13}$/.test(taxID);

  const validateThaiPhoneNumber = (tel) => /^0\d{8,9}$/.test(tel);

  const handleApprove = async () => {
    try {

      if (!supplier) {
        return AlertError("Please insert the supplier!");
      }

      if (!validateLocation(location)) {
        return AlertError("Location must be 3-100 characters and use valid symbols.");
      }

      if (!validateThaiTaxID(tax_id)) {
        return AlertError("Tax ID must be a 13-digit number.");
      }

      if (!validateThaiPhoneNumber(tel)) {
        return AlertError("Phone number must start with 0 and contain 9 or 10 digits.");
      }


      const events = {
        supplier,
        location,
        tax_id,
        condition: condition?.trim() || '',
        tel,
        vat: 7,
      };

      const apiUrls = `${baseURL}/purchase/po/approve/${temp_store(props).location.state.row.id}`;
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      };

      const result = await Swal.fire({
        title: "Are you sure?",
        text: "The purchase order will be approved.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Approve",
        cancelButtonText: "Cancel",
      });

      if (result.value) {
        try {
          await axios.put(apiUrls, events, { headers });
          Swal.fire("Success!", "Purchase order approved successfully.", "success");
          history.goBack();
        } catch (err) {
          const errorMessage = err.response?.data?.message || err?.message || "An unknown error occurred.";
          Swal.fire("Error!", `Could not approve the purchase order: ${errorMessage}`, "error");
        }
      }
    } catch (error) {
      console.log("Unexpected error:", error?.response || error.message);
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "11401",
            defaultMessage: "Approve Purchase Order",
          })}
        ></CardHeader>
        <CardBody>
          
        <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113391" ,defaultMessage:"Supplier" })}:{" "}
              </p>
              <input
                name="supplier"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={supplier}
                onChange={(supplier) => setSupplier(supplier.target.value)}
              />
            </div>
          </div>
          <hr></hr>
          
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113392" ,defaultMessage:"Tel" })}:{" "}
              </p>
              <input
                name="tel"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={tel}
                onChange={(tel) => setTel(tel.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113393" ,defaultMessage:"Location" })}:{" "}
              </p>
              <input
                type="text"
                name="location"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={location}
                onChange={(location) => setLocation(location.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113394" ,defaultMessage:"Tax ID" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={tax_id}
                onChange={(tax_id) => setTax(tax_id.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}> 
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113395" ,defaultMessage:"Condition" })}:{" "}
              </p>
              <input
                type="text"
                name="condition"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={condition}
                onChange={(condition) => setCondition(condition.target.value)}
              />
            </div>
          </div>
          <hr></hr>

        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113169" ,defaultMessage:"Description" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                disabled
              />
            </div>
          </div>
          <hr></hr>
          <BootstrapTable
            keyField="item_id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />

          <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
            <div className="container mt-3">
              <div className="form-inline d-flex justify-content-center">
                <Link to="/PurchaseOrder">
                  <button type="button" className="btn btn-secondary wbt">
                    {intl.formatMessage({
                      id: "107016",
                      defaultMessage: "Cancel",
                    })}
                  </button>
                </Link>
                <button className="btn btn-primary" onClick={() => handleApprove()}>
                  {intl.formatMessage({ id: "113385", defaultMessage: "Approve" })}
                </button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
