import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import { temp_store } from "../../../modules/function/temp_store.js"
import { generatePdfOrder } from '../../component/ButtonGeneratePurchaseOrderPdf.js'

export const ViewPurchaseOrder = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113384" ,defaultMessage:"Purchase Order"})
  );
  const history = useHistory();
  const [listData, setListData] = useState([]);
  const [description, setDescription] = useState('')
  const [supplier, setSupplier] = useState('')
  const [tel, setTel] = useState('')
  const [location, setLocation] = useState('')
  const [tax_id, setTax] = useState('')
  const [condition, setCondition] = useState('')
  const [branchData, setBranchData] = useState([]);
  const [poData, setPoData] = useState([]);

  // fetch data มาใส่ list 
  // const gettingData = async () => {
  //   let allData = [];
  //   await axios({
  //     method: "get",
  //     url: `${baseURL}/purchase/po/pdf/${temp_store(props).location.state.row.id}`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   }).then((res) => {
  //     console.log(res.data.data);
  //     setSupplier(res.data.data.po[0].supplier)
  //     setLocation(res.data.data.po[0].address)
  //     setTax(res.data.data.po[0].tax_id)
  //     setTel(res.data.data.po[0].tel)
  //     setCondition(res.data.data.po[0].po_condition)
  //     setListData(res.data.data.list)
  //     var dataresponse = res.data.data // ซ้ำป่ะ
  //     setPoData(dataresponse.po);
  //     setListData(dataresponse.list);
  //   });
  // };
  const gettingData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${baseURL}/purchase/po/pdf/${temp_store(props).location.state.row.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      const data = res.data.data;
      console.log(`**** data ****`, data);
      // setSupplier(data?.po[0]?.supplier || "");
      // setLocation(data?.po[0]?.address || "");
      // setTax(data?.po[0]?.tax_id || "");
      // setTel(data?.po[0]?.tel || "");
      // setCondition(data?.po[0]?.po_condition || "");
      // setDescription(data?.po[0]?.description || "");
      if (data.po) {
        setSupplier(data.po.supplier || "");
        setLocation(data.po.address || "");
        setTax(data.po.tax_id || "");
        setTel(data.po.tel || "");
        setCondition(data.po.po_condition || "");
        setDescription(data.po.description || "");
      } else {
        Swal.fire("Data is missing 'po' array.");
      }

      setPoData([data?.po]);
      setListData(data?.list);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getShopDetail = async () => {
    axios({
      method: "get",
      url: `${baseURL}/management/shopBranchDetail`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        const branchData = {
          address: res.data.data[0].address,
          tel: res.data.data[0].tel,
          branch_name: res.data.data[0].name,
          shop_name: res.data.data[1].name,
          tax_id: res.data.data[0].tax_id
        };
        console.log("type0f======>", branchData)
        setBranchData(prevData => [...prevData, branchData]);
      }
      
    });
  };
  const generatePdf = () => {
    console.log("datatogenPdf---->")
    console.log(poData)
    console.log(branchData)
    console.log(listData)
    generatePdfOrder(poData, branchData, listData)
  }

  useEffect(() => {
    gettingData()
    getShopDetail()
  }, []);
  

  const columns = [
    {
      dataField: "item_id",
      text: "ID",
    },

    {
      dataField: "name",
      text: "item name",
    },

    {
      dataField: "qty",
      text: "quantity",
    },
    {
      dataField: "unit_name",
      text: "unit name",
    },

    {
      dataField: "price_per_unit",
      text: "price per unit",
    }
  ];

  const reject = async(item_id,rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Item will be excluded from Purchase order!",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Reject",
      cancelButtonText: "Cancel",
    }).then((result) => {
        if (result.value) {
          axios({
            method: "get",
            url: `${baseURL}/purchase/item/reject/${item_id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }).then((res) => {
            deleteItem(rowIndex)
          });
        } 
    });

  }

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }
  

  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };

  /* const handleApprove = async () => {
    var events = {
        supplier: supplier,
        location: location,
        tax_id: tax_id,
        condition: condition == '' ? '' : condition,
        tel:tel,
        vat:7
    }

    let apiUrls = `${baseURL}/purchase/po/approve/${temp_store(props).location.state.row.id}`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
    .then(async (res) => {
      Swal.fire("Save !", "PO Approved", "success");
      history.goBack();
    })
    .catch((err) => {
      Swal.fire(
        "Created !",
        "Your can not approve PO. !! " + err,
        "error"
      );
    });
  } */
  const handlePrint = () => {
    generatePdf(poData, branchData, listData)
  }

  const handleApprove = async () => {

    if (supplier === undefined || supplier === "") {
        return AlertError("Please insert Supplier !!");
    }
    if (location === undefined || location === "") {
        return AlertError("Please insert Location !!");
    }
    if (tax_id === undefined || tax_id === "") {
        return AlertError("Please insert Tax ID !!");
    }
    if (tel === undefined || tel === "") {
        return AlertError("Please insert Phone number !!");
    }
    var events = {
      supplier: supplier,
      location: location,
      tax_id: tax_id,
      condition: condition == '' ? '' : condition,
      tel:tel,
      vat:7
    }

    let apiUrls = `${baseURL}/purchase/po/approve/${temp_store(props).location.state.row.id}`;
    /* axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
    .then(async (res) => {
      Swal.fire("Save !", "Purchase order aprrove", "success");
      history.goBack();
    })
    .catch((err) => {
      Swal.fire(
        "Created !",
        "Your can not approve purchase order. !! " + err,
        "error"
      );
    }); */
    Swal.fire({
      title: "Are you sure?",
      text: "Purchase order will be approve.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Approve",
      cancelButtonText: "Cancel",
    }).then((result) => {
        if (result.value) {
          axios({
            method: "put",
            url: apiUrls,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            data: events,
          })
          .then(async (res) => {
            Swal.fire("Save !", "Purchase order aprrove", "success");
            history.goBack();
          })
          .catch((err) => {
            Swal.fire(
              "Created !",
              "Your can not approve purchase order. !! " + err,
              "error"
            );
          });
          
        } 
    });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "11401",
            defaultMessage: "Approve Purchase Order",
          })}
        ></CardHeader>
        <CardBody>
          
        <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113391" ,defaultMessage:"Supplier" })}:{" "}
              </p>
              <input
                name="supplier"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={supplier}
                onChange={(supplier) => setSupplier(supplier.target.value)}
                disabled
              />
            </div>
          </div>
          <hr></hr>
          
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113392" ,defaultMessage:"Tel" })}:{" "}
              </p>
              <input
                name="tel"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={tel}
                onChange={(tel) => setTel(tel.target.value)}
                disabled
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113393" ,defaultMessage:"Location" })}:{" "}
              </p>
              <input
                type="text"
                name="location"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={location}
                onChange={(location) => setLocation(location.target.value)}
                disabled
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113394" ,defaultMessage:"Tax ID" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={tax_id}
                onChange={(tax_id) => setTax(tax_id.target.value)}
                disabled
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}> 
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113395" ,defaultMessage:"Condition" })}:{" "}
              </p>
              <input
                type="text"
                name="condition"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={condition}
                onChange={(condition) => setCondition(condition.target.value)}
                disabled
              />
            </div>
          </div>
          <hr></hr>

        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113169" ,defaultMessage:"Description" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                disabled
              />
            </div>
          </div>
          <hr></hr>
          <BootstrapTable
            keyField="item_id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >

            <div className="container mt-3">
              <div className="form-inline d-flex justify-content-center">
                <Link to="/PurchaseOrder">
                  <button type="button" className="btn btn-secondary wbt">
                    {intl.formatMessage({
                      id: "107016",
                      defaultMessage: "Cancel",
                    })}
                  </button>
                </Link>
                <button className="btn btn-primary" onClick={() => handlePrint()}>
                  {intl.formatMessage({ id: "113406", defaultMessage: "Print" })}
                </button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
    
  );
};
