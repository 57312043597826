import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';
import { generatePdfOrder } from './component/ButtonGeneratePurchaseOrderPdf.js'

export const PurchaseOrder = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const allData = [];
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalSize, setTotalSize] = useState(100);
    suhbeader.setTitle(intl.formatMessage({ id: "113384" ,defaultMessage:"Purchase Order"}));
    const { authToken } = useSelector((state) => state.auth);
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [roleList, setRoleList] = useState([]);
    const [poData, setPoData] = useState([]);
    const [listData, setListData] = useState([]);
    const [branchData, setBranchData] = useState([]);

    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem('edit')
        checkAuth();
        getShopDetail();
    }, []);

    // useEffect(() => {
    //     if (poData.length > 0 && listData.length > 0) {
    //         // console.log("UseeffectpoData====>",poData)
    //         console.log("Ready to generate PDF");
    //         generatePdf();
    //     }
    // }, [poData, listData]);
    useEffect(() => {
        if (poData.length > 0 && listData.length > 0 && branchData.length > 0) {
            console.log("Generating PDF with data:", { poData, branchData, listData });
            // generatePdf();
            generatePdfOrder(poData, branchData, listData)
        }
    }, [poData, listData, branchData]);

    // const checkAuth =async()=> {
    //     const data = await roleCheck('DELIVERY_NOTE')
    //     setRoleList(data)
    //     console.log(data)
    //     if (!data.includes('VIEW')) {
    //       history.push('/auth/login')
    //     }
    //     let value = 1
    //     if(localStorage.getItem("pagePrinterZone") != null){
    //         value = localStorage.getItem("pagePrinterZone")
    //     }
    //     let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
    //     setCampaigns_id_headder(campaigns_id_headders);
    //     getList(value, 10, search, campaigns_id_headders);
    //   }

    const checkAuth = async () => {
        try {
            const data = await roleCheck('DELIVERY_NOTE');
            console.log(data);
            setRoleList(data);

            if (!data.includes('VIEW')) {
                history.push('/auth/login');
                return;
            }

            let value = 1;
            if (localStorage.getItem("pagePrinterZone") !== null) {
                value = parseInt(localStorage.getItem("pagePrinterZone"), 10); // แปลงเป็นตัวเลข
            }

            const campaigns_id_headders = localStorage.getItem("campaigns_id_headder");
            setCampaigns_id_headder(campaigns_id_headders);

            await getList(value, 10, search, campaigns_id_headders);
        } catch (error) {
            console.error("Error in checkAuth:", error);

            Swal.fire("An error occurred while checking authentication. Please try again.");
        }
    };

    // const getList = async (page, limit, search, campaigns_id_headders) => {
    //     setPage(page);
    //     axios({
    //         method: "get",
    //         url: `${baseURL}/purchase/po?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
    //             }`,
    //         headers: {
    //             Accept: "application/json",
    //             Authorization: `Bearer ${authToken}`,
    //         },
    //     }).then((res) => {
    //         if (res.message) {
    //             history.push("/logout");
    //         } else {
    //             console.log(res.data.data)
    //             res.data.data.map((data, index) => {
    //                 data = {
    //                     id: data.id,
    //                     status: data.status,
    //                     po_no: data.invoice_no,
    //                     ref_no: data.pr_no,
    //                     created_at: data.created_at,
    //                     // created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
    //                 };
    //                 allData.push(data);
    //             });
    //         }

    //         var totalpage = Math.ceil(res.data.total / res.data.limit);
    //         setTotalPage(totalpage);
    //         setTotalSize(res.data.total);
    //         setLimit(res.data.limit);
    //         setSettingPrinterZoneData(allData);
    //     });
    //     /* var mockdata = {
    //         id: 1,
    //         status: "PENDING",
    //         po_no: "PO1005210014",
    //         ref_no: "PR1005119007",
    //         department: "Kitchen",
    //         created_at: "10-02-2025 10:00:00:53",
    //         no_of_item: "3",
    //     };

    //     allData.push(mockdata); */
    //     setSettingPrinterZoneData(allData);
    // };

    const getList = async (page, limit, search, campaigns_id_headers) => {
        setPage(page);
        let allData = []; // Reset array

        try {
            const res = await axios({
                method: "get",
                url: `${baseURL}/purchase/po?page=${page}&limit=${limit}${search ? `&search=${search}` : ""
                    }`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (res.data.message) {
                history.push("/logout");
                return;
            }

            const transformedData = res.data.data.map((data) => ({
                id: data.id,
                status: data.status,
                po_no: data.invoice_no,
                ref_no: data.pr_no,
                created_at: data.created_at,
            }));

            const totalPage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalPage);
            setTotalSize(res.data.total);
            setLimit(res.data.limit);
            setSettingPrinterZoneData(transformedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        console.log('**** settingPrinterZone ****', settingPrinterZone)
    }, [settingPrinterZone])

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "po_no",
            text: "PO_NO",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "ref_no",
            text: "REF",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        {
            dataField: "created_at",
            text: 'created at',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },

        // {
        //     dataField: "created_at",
        //     text: "Create Date",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            text: intl.formatMessage({ id: "107008",defaultMessage:"Action" }),
            dataField: "id",
            formatter: (id, row, rowIndex) => {
                return (
                    <div>
                        {roleList.includes('DELETE') &&
                            (row.status == 'pending') &&
                            <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={() => approveItem({ id,row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "113385",defaultMessage:"approve" })}
                        </button>}
                        {roleList.includes('DELETE') &&
                            (row.status == 'pending') &&
                            <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => rejectItem({ id,row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "113388",defaultMessage:"Reject" })}
                        </button>}
                        {(row.status != 'pending' &&
                            row.status != 'reject') &&
                            <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => viewItem({ id,row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "113409",defaultMessage:"View" })}
                        </button>}
                        {(row.status != 'pending' &&
                            row.status != 'reject') &&
                            <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => printItem({ id,row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "113406",defaultMessage:"Print" })}
                        </button>}
                    </div>
                );
            },
        },
    ];

    const viewItem = (data) => history.push("/ViewPurchaseOrder", data);

    const approveItem = (data) => history.push("/EditPurchaseOrder", data);

    // const printItem = (data) => gettingData(data);
    const printItem = async (data) => {
        // setPoData([]);  
        // setListData([]); 

        await gettingData(data);
    };

    // const getShopDetail = async () => {
    //     await axios({
    //       method: "get",
    //       url: `${baseURL}/management/shopBranchDetail`,
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: `Bearer ${authToken}`,
    //       },
    //     }).then((res) => {
    //       if (res.message) {
    //         history.push("/logout");
    //       } else {
    //         const branchData = {
    //             address: res.data?.data[0]?.address,
    //             tel: res.data?.data[0]?.tel,
    //             branch_name: res.data?.data[0]?.name,
    //             shop_name: res.data?.data[1]?.name,
    //             tax_id: res.data?.data[0]?.tax_id
    //         };
    //         console.log("type0f======>", branchData)
    //         setBranchData(prevData => [...prevData, branchData]);
    //       }
          
    //     });
    //   };

    const getShopDetail = async () => {
        try {
            const res = await axios({
                method: "get",
                url: `${baseURL}/management/shopBranchDetailV2`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (res.message) {
                history.push("/logout");
            } else {
                const data = res.data.data[0] || {}; 
                const branchFetchData = {
                    id: data.id || moment().getTime(),
                    address: data.address,
                    tel: data.tel,
                    branch_name: data.name,
                    shop_name: data.name,
                    tax_id: data.tax_id,
                };

                // setBranchData((prevData) => [...prevData, branchFetchData]); 
                // setBranchData((prevData) => [
                //     ...prevData,
                //     { ...branchData, id: branchData.id || `${moment().valueOf()}` }, 
                // ]);
                // setBranchData((prevData) => prevData.concat(branchFetchData));
                setBranchData([...branchData, branchFetchData]); 
            }
        } catch (error) {
            console.error("Error fetching shop details:", error);
        }
    };

    useEffect(() => {
        console.log('**** branchData ****', branchData)
    }, [branchData])
    
    // const gettingData = async (data) => {
        
    //     await axios({
    //       method: "get",
    //       url: `${baseURL}/purchase/po/pdf/${data.id}`, 
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: `Bearer ${authToken}`,
    //       },
    //     }).then((res) => {
    //       console.log("data response getting data",res.data.data);
    //       var dataresponse = res.data.data
    //       setPoData(dataresponse.po);
    //       setListData(dataresponse.list);

    //     });
    //   };

      const generatePdf = () =>{
        console.log("datatogenPdf---->")
        console.log(poData)
        console.log(branchData)
        console.log(listData)
        generatePdfOrder(poData,branchData,listData)
        setPoData([]);
        setListData([]);
    }

    const gettingData = async (data) => {
        try {
            const res = await axios({
                method: "get",
                url: `${baseURL}/purchase/po/pdf/${data.id}`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            const dataresponse = res.data.data;
            console.log('**** dataresponse ****', dataresponse)
            console.log('**** dataresponse po ****', dataresponse.po)
            console.log('**** dataresponse list ****', dataresponse.list)
            // setPoData(dataresponse?.po);
            // setListData(dataresponse?.list);
            setPoData([dataresponse?.po]);
            setListData([...dataresponse?.list]); 
        } catch (error) {
            console.error("Error getting data:", error);
        }
    };

    useEffect(() => {
        console.log('**** poData ****', poData)
        console.log('**** listData ****', listData)
    }, [poData,listData])

    const rejectItem = (data) => {
        let apiUrls = `${baseURL}/purchase/po/reject/${data.id}`;
        /* axios({
            method: "get",
            url: apiUrls,
            headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            }
        })
        .then(async (res) => {
            Swal.fire("Save !", "Purchase order Reject", "success");
            getList(1, limit, search, campaigns_id_headder);
        })
        .catch((err) => {
            Swal.fire(
            "Created !",
            "Your can not reject purchase order. !! " + err,
            "error"
            );
        }); */
        Swal.fire({
            title: "Are you sure?",
            text: "Purchase order will be reject.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Reject",
            cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.value) {
                axios({
                    method: "get",
                    url: apiUrls,
                    headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${authToken}`,
                    }
                })
                .then(async (res) => {
                    Swal.fire("Save !", "Purchase order Rejected", "success");
                    getList(1, limit, search, campaigns_id_headder);
                })
                .catch((err) => {
                    Swal.fire(
                    "Created !",
                    "Your can not reject purchase order. !! " + err,
                    "error"
                    );
                });
                } 
            });
        };

    const [search, setSearch] = useState();
    const handleSearch = () => {
        setPage(1);
        getList(1, limit, search, campaigns_id_headder);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        localStorage.setItem("pagePrinterZone", value)
        getList(value, limit, search, campaigns_id_headder);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getList(1, event.target.value, search, campaigns_id_headder);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113384",defaultMessage:"Purchase Order" })}>
            </CardHeader>
            <CardBody>
                <div className="row form-inline mb-2">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "107002" ,defaultMessage:"Text Search"})}
                                style={{ width: "100%" }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="form-group">
                            <button
                                type="button"
                                id="button"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={() => handleSearch()}
                            >
                                {intl.formatMessage({ id: "107003",defaultMessage:"search" })}
              </button>
                        </div>
                    </div>
                </div>

                <BootstrapTable
                    keyField="po_no"
                    data={settingPrinterZone === undefined ? [] : settingPrinterZone}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={settingPrinterZone} />
                    <NoRecordsFoundMessage entities={settingPrinterZone} />
                </BootstrapTable>
                <div className=" col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {intl.formatMessage({ id: "107032",defaultMessage:"Item per page" })}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3 "
                                count={totalPage}
                                page={localStorage.getItem("pagePrinterZone") != null ? Number(localStorage.getItem("pagePrinterZone")) : page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
