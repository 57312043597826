import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const EditStock = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113206", defaultMessage: "Stock" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [usedType, setUsedType] = useState("IMPORT");
  const [reason, setReason] = useState(null);
  const [description, setDescription] = useState(null);
  const [qty, setQTY] = useState(null);
  const [role, setRole] = useState("");
  const [listUnit, setListUnit] = useState([]);
  const [mainUnit, setMainUnit] = useState([]);
  const [shop, setShop] = useState([]);
  const [price, setPrice] = useState(0);
  const [convert, setConvert] = useState(1);
  const [branch, setBranch] = useState([]);
  const [branch_id, setBranch_id] = useState(-1);
  const [unitType, setUnitType] = useState("major");
  const [material_id, setMaterial_id] = useState(-1);
  const [unit, setUnit] = useState([
    {
      key: "major",
      value: "",
    },
    {
      key: "minor",
      value: "",
    },
  ]);
  useEffect(() => {
    getListMaterial();
  }, []);

  // useEffect(() => {
  //   if (usedType == "TRANSFER") {
  //     let token = localStorage.getItem("Token");
  //     axios({
  //       method: "get",
  //       url: `${baseURL}/inventory/branch?search=&page=1&limit=1000`,
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.message) {
  //         } else {
  //           const data = res.data.data;
  //           setBranch(data);
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  // }, [usedType]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (usedType === "TRANSFER") {
          let token = localStorage.getItem("Token");
          let branchId = localStorage.getItem("branch_id");

          console.log(branchId)

          const res = await axios({
            method: "get",
            url: `${baseURL}/inventory/branch?search=&page=1&limit=1000`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (res.data && res.data.data) {
            const data = res.data.data;
            const filteredData = data.filter(item => item.id !== Number(branchId))
            console.log("filteredData", filteredData)
            setBranch(filteredData);
          } else {
            console.log("No data found or invalid response structure.");
          }
        }
      } catch (err) {
        console.log("Error occurred in API call:", err);
      }
    };

    fetchData();
  }, [usedType]);

  // useEffect(() => {
  //   console.log("**** branch ****", branch)
  // }, [branch]);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/inventory?search=&page=1&limit=1&filter=${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          const data = res.data.data[0];
          console.log(data);
          // let main = res.data.data.filter(x=> x.id == props.location.state.row.material_id)
          setMaterial_id(data.material_id);
          setMainUnit(data);
          setUnit([
            {
              key: "major",
              value: data.major_unit_name,
            },
            {
              key: "minor",
              value: data.minor_unit_name,
            },
          ]);
          setConvert(data.qty_out_used);
        }
      })
      .catch((err) => {});
  };

  const onSubmit = async () => {
    const removeType = usedType === "REMOVE"
    if (!removeType && (qty == undefined || qty == "" || qty == null)) {
      return AlertError("Please insert quantity");
    }
    if (!removeType && (reason == undefined || reason == "" || reason == null)) {
      return AlertError("Please insert reason");
    }
    console.log(props.location.state.row);
    var events = {
      branch_id: branch_id,
      material_id: material_id,
      quantity: qty,
      method: usedType,
      unit_type: unitType,
      price: price,
      reason: reason,
    };
    let apiUrls = `${baseURL}/inventory`;
    let token = localStorage.getItem("Token");
    console.log(events);
    Swal.fire({
      title: "",
      text: "Confirm Edit",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            // Swal.fire("Edit !", "Your can not edit. !! " + err, "error");

            // console.log("Error response:", err.response);
            // console.log("Error response data:", err.response.data);
            // console.log("Error response data message:", err.response.data.system_response.message.message);

            let errorMessage = "An error occurred!";
            if (err.response && err.response.data && err.response.data.system_response.message.message
            ) {
              errorMessage = err.response.data.system_response.message.message;
            }
            Swal.fire("Edit Error", errorMessage, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp = {
      page: "",
      page_id: "",

      permission: [],
    };
    setData((data) => [...data, temp]);
  };

  const DeleteQuestion = (index) => {
    var array = [...data]; // make a separate copy of the array
    array.splice(index, 1);
    setData(array);
  };

  return (
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113223",
            defaultMessage: "Adjust Stock",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113225",
                defaultMessage: "Type",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              options={["IMPORT", "EXPORT", "REJECT", "TRANSFER", "REMOVE"]} // add REMOVE
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                console.log(newValue);
                setUsedType(newValue);
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>
          {usedType === "TRANSFER" && (
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "108013",
                  defaultMessage: "Branch",
                })}
                <span style={{ color: "red" }}>*</span>:{" "}
              </p>
              <Autocomplete
                options={branch}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  // console.log("**** branch_id ****", newValue.id);
                  setBranch_id(newValue.id);
                }}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div>
          )}
          {usedType === "IMPORT" && (
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113276",
                  defaultMessage: "Price (Baht)",
                })}{" "}
                :{" "}
              </p>
              <input
                type="number"
                name="modelGroup"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          )}

        {usedType !== "REMOVE" &&
          <>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113235",
                defaultMessage: "Unit",
              })}
              <span style={{ color: "red" }}>*</span>:
            </p>
            <Autocomplete
              options={unit}
              getOptionLabel={(option) => option.value}
              onChange={(event, option) => {
                console.log(option);
                setUnitType(option.key);
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "999999999999",
                defaultMessage: "Convert",
              })}{" "}
              :{" "}
            </p>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {unitType == "major" ? `1:${convert}` : `${convert}:1`}
            </p>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113221",
                defaultMessage: "Quantity",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              type="number"
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={qty}
              onChange={(e) => setQTY(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113224",
                defaultMessage: "Reason",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <textarea
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          </>
        }

        {/* <div
          className="form-inline"
          style={{ marginBottom: "25px", }}
        > */}
        {/* <div style={{ marginTop: "15px", float: "left", width: "20%", }}>
          </div> */}
        <div className="container mt-3">
          <div className="form-inline d-flex justify-content-center"
          >
            <Link to="/Stock">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113245", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "112044", defaultMessage: "Save" })}
            </button>
          </div>
        </div>
        {/* </div> */}
        </CardBody>
    </Card>
  );
};
