import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import Swal from 'sweetalert2';

export const generatePdfOrder = async (data, branchData, listData) => {
    console.log("data---------->", data)
    console.log("branchdata---------->", branchData)
    console.log("listdata---------->", listData)

    if (!data || !branchData || !listData) {
        Swal.fire("Missing required data");
        return;
    }

    const date = new Date();
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options);

    const sum_total = (listData || []).reduce((acc, item) => acc + (parseFloat(item.price_per_unit || "0") || 0), 0);
    const sum_qty = (listData || []).reduce((acc, item) => acc + (parseFloat(item.qty || "0") || 0), 0);

    const transformedData = listData.map((item, index) => {
        return {
            ...item,
            number: index + 1
        };
    });

    const doc = new jsPDF();
    let yPosition = 110;
    let yPositionEnd;
    let lineText = "............................................"
    doc.setFont('SukhumvitSet-Medium');

    doc.setFontSize(10);
    doc.text(`ชื่อบริษัท : ${branchData[0]?.shop_name}`, 25, 20); // margin ซ้าย 25 mm บน 20 mm
    doc.text(`สาขา : ${branchData[0]?.branch_name}`, 80, 20);
    doc.text(`ที่อยู่ : ${branchData[0]?.address}`, 25, 25);
    doc.text(`เลขประจำตัวผู้เสียภาษี : ${branchData[0]?.tax_id}`, 25, 30);

    doc.setFontSize(20);
    doc.text(`ใบสั่งซื้อ: Purchase Order (PO)`, 60, 50);

    doc.setFontSize(10);
    doc.text(`เลขที่ : ${data[0]?.invoice_no}`, 150, 60);
    doc.text(`วันที่ : ${formattedDate}`, 150, 65);

    doc.text(`ผู้จำหน่าย : ${data[0]?.supplier}`, 25, 60);
    doc.text(`ที่อยู่ : ${data[0]?.address}`, 25, 65);
    doc.text(`เลขประจำตัวผู้เสียภาษี : ${data[0]?.tax_id}`, 25, 70);
    doc.text(`โทร : ${data[0]?.po_tel == undefined ? "-" : data[0]?.po_tel}`, 25, 75);
    doc.text(`อ้างอิง : ${data[0]?.pr_no}`, 25, 80);

    doc.setFont('SukhumvitSet-Medium');
    const columns = [
        { header: 'ลำดับ', dataKey: 'no' },
        { header: 'รายการ', dataKey: 'listData_name' },
        { header: 'จำนวน', dataKey: 'qty' },
        { header: 'หน่วย', dataKey: 'unit_name' },
        { header: 'ราคาต่อหน่วย', dataKey: 'unit_price' },
    ];

    const rows = transformedData.map(item => ({
        no: item.number,
        listData_name: item.name,
        qty: item.qty,
        unit_name: item.unit_name,
        unit_price: parseFloat(item.price_per_unit).toFixed(2),
    }));
    const rowCount = rows.length;
    yPositionEnd = yPosition + ((rowCount + 1) * 10)

    const columnWidths = [15, 75, 15, 25, 30]; 

    const table2Row = [
        [`หมายเหตุ:${data[0]?.po_condition || "-"}`, "รวมเป็นเงิน", `${parseFloat(data[0]?.pre_vat).toFixed(2)}`],
        ["", "จำนวนภาษีมูลค่าเพิ่ม 7%", `${parseFloat(data[0]?.vat || "0").toFixed(2)}`],
        ["", "จำนวนเงินรวมทั้งสิ้น", `${parseFloat(data[0]?.grand_total || "0").toFixed(2)}`],
    ];

    try {
        doc.autoTable({
            startY: yPosition,
            margin: { left: 25, right: 25 },

            head: [columns.map(col => col.header)],
            body: rows.map(row => Object.values(row)),
            theme: 'grid',
            headStyles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 12,
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
            },
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0], halign: 'center' }, // ลำดับ
                1: { cellWidth: columnWidths[1], halign: 'left' },   // รายการ
                2: { cellWidth: columnWidths[2], halign: 'center' },  // จำนวน
                3: { cellWidth: columnWidths[3], halign: 'right' }, // หน่วย
                4: { cellWidth: columnWidths[4], halign: 'right' },  // ราคาต่อหน่วย
            },
        });

        const finalY = doc.lastAutoTable.finalY;
        doc.autoTable({
            margin: { left: 25, right: 25 },
            theme: 'grid',
            head: [],
            body: table2Row, 
            startY: finalY,
            styles: {
                font: 'SukhumvitSet-Medium',
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0] + columnWidths[1], halign: 'left' },
                1: { cellWidth: columnWidths[2] + columnWidths[3], halign: 'right' },
                2: { cellWidth: columnWidths[4], halign: 'right' },
            },

        });

        doc.text(`${lineText}`, 30, 260)
        doc.text(`(${lineText})`, 30, 270)
        doc.text(`ผู้สั่งซื้อสินค้า`, 40, 275)
        doc.text(`วันที่`, 25, 282)
        doc.text(`${lineText}`, 32, 282)

        doc.text(`${lineText}`, 130, 260)
        doc.text(`(${lineText})`, 130, 270)
        doc.text(`ผู้รับมอบอำนาจ`, 140, 275)
        doc.text(`วันที่`, 125, 282)
        doc.text(`${lineText}`, 132, 282)

        const filename = `${data[0]?.invoice_no || 'document'}.pdf`;
        doc.save(filename);
    } catch (error) {
        console.log('Error while generating PDF:', error);
        Swal.fire("Error", "An error occurred while generating the PDF. Please try again.", "error");
    }
}

