import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { BorderColor, CheckBox } from "@material-ui/icons";
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditDeliveryHours = (props) => {
  const intl = useIntl();
  console.log('props CreateMenuGroup', props);
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  const classes = useStyles();
  suhbeader.setTitle(
    intl.formatMessage({ id: "102007", defaultMessage: "Shop/Branch" })
  );
  const history = useHistory();

  const [isChecked, setIsChecked] = useState([]);
  const [state, setState] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [daydata, setdaydata] = useState([]);
  const [additionalTimeRows, setAdditionalTimeRows] = useState({});
  const [type, setType] = useState('component')
  let initTimeSlot = {
    id: null,
    date_id: null,
    open_time: null,
    close_time: null,
  };
  let lang = localStorage.getItem("Language") || 'en';

  const [checkedDays, setCheckedDays] = useState({
    1: true, // Monday
    2: true, // Tuesday
    3: true, // Wednesday
    4: true, // Thursday
    5: true, // Friday
    6: true, // Saturday
    7: true, // Sunday
  });
  useEffect(() => {
    if (props.type === 'dialog') {
      setType(props.type);
    }
  }, []);
  const AlertError = (message) => {
    Swal.fire("EditShop!", message, "error");
  };

  /* transformAdditionalRow */
  const transformDataAddTime = (input) => {
    let output = [];

    Object.keys(input).forEach((date_id) => {
      input[date_id].forEach((item, index) => {
        output.push({
          date_id: date_id,
          index: index,
          start_time: item.start_time,
          end_time: item.end_time,
          is_close: false,
        });
      });
    });

    return output;
  };
  const updatedCheckedDays = { ...checkedDays };
  /* time config */
  // const handleTimeChange = (indexDay, indexTime, date_id, value, name) => {
  //   let updateTimeArr = [...daydata]
  //   updateTimeArr[indexDay].time_slot[indexTime][name] = value
  //   setdaydata(updateTimeArr)
  // };

  const handleTimeChange = (indexDay, indexTime, date_id, value, name) => {
    const updatedTime = value ? moment(value).format("HH:mm") : null; // แปลงเป็น HH:mm หรือกำหนดเป็น null
    const updatedData = [...daydata];
    updatedData[indexDay].time_slot[indexTime][name] = updatedTime;
    setdaydata(updatedData); // อัปเดต state ให้ถูกต้อง
  };

  const handleDeleteRow = (indexDay, indexTime, date_id) => {
    let newArray = [...daydata];

    // Mark the time_slot as deleted
    newArray[indexDay].time_slot[indexTime].is_delete = 1;

    // Update daydata
    setdaydata(newArray);

    // Check if all time_slots for the current day have is_delete === 1
    const allDeleted = newArray[indexDay].time_slot.every(slot => slot.is_delete === 1);

    if (allDeleted) {
      setCheckedDays((prevCheckedDays) => ({
        ...prevCheckedDays,
        [date_id]: false,
      }));
    }

  };
  const handleCheckboxChange = (day) => {
    setCheckedDays({
      ...checkedDays,
      [day]: !checkedDays[day],
    });
  };
  /* add rows with new time */
  const handleAddTime = (indexDay, indexTime, date_id) => {
    initTimeSlot.date_id = date_id
    const newArray = [...daydata];
    newArray[indexDay].time_slot.push(initTimeSlot);
    setdaydata(newArray);
  };

  const onsubmit = async () => {
    let shop_id = localStorage.getItem("shop_id");
    let branch_id = localStorage.getItem("branch_id");
    console.log("daydata on submit================>", daydata);
    const checkedArray = Object.keys(checkedDays).filter(
      (day) => checkedDays[day]
    );
    setIsChecked(checkedArray);;
    console.log("checkedArray====================>", checkedArray);

    const newdaydata = daydata.map((date) => {
      // console.log("date before map=========>", date);
      const newData = { ...date };
      const dateString = String(date.date_id);

      //check if which daydata is close
      if (checkedArray.includes(dateString)) {
        return {
          date_id: date.date_id,
          datename_en: date.datename_en,
          time_slot: date.time_slot

        };
      } else {
        return {
          date_id: date.date_id,
          datename_en: date.datename_en,
          time_slot: date.time_slot,
          is_closed: 1,
        };
      }
    });
    console.log("data after check=========>", newdaydata);
    if (!validateAllTimeSlots(newdaydata)) {
      return;
    }
    var events = {
      shop_id: shop_id,
      branch_id: branch_id,
      working_slot_detail: newdaydata,
    };
    console.log("event======================>", events);
    let apiUrls = `${baseURL}/management/deliveryslot`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        if (type == 'dialog') {
          props.handle('save')
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "You can not save delivery hours. !! " + err,
          "error"
        );
      });
    // .then(async (res) => {
    //   Swal.fire("Save !", "Save working time success", "success");
    //   history.goBack();
    // })
    // .catch((err) => {
    //   Swal.fire(
    //     "Save !",
    //     "Your can not save working time. !! " + err,
    //     "error"
    //   );
    // });
  };

  function validateAllTimeSlots(data) {
    console.log("***validateAllTimeSlots===>")
    const errors = [];

    for (const day of data) {
      if (day.is_closed !== 1) {
        for (const slot of day.time_slot) {
          if (slot.is_delete) {
            return true;
          } else {
            const openTimeDate = new Date(`1970-01-01T${slot.open_time}`);
            const closeTimeDate = new Date(`1970-01-01T${slot.close_time}`);

            if (openTimeDate > closeTimeDate) {
              AlertError(`Error: Open time (${slot.open_time}) is greater than close time (${slot.close_time})  on ${day.datename_en}`);
              return false; // Stop further validation if an error is found
            }
            if (openTimeDate === closeTimeDate) {
              AlertError(`Error: Open time (${slot.open_time}) is the same time of close time (${slot.close_time})  on ${day.datename_en}`);
              return false; // Stop further validation if an error is found

            }
            if (slot.open_time === null || slot.open_time === undefined || slot.close_time === null || slot.close_time === undefined) {
              AlertError(`Error: Open time  or closeTime is invalid on ${day.datename_en}`);
              return false; // Stop further validation if an error is found
            }

          }

        }
        for (let i = 0; i < day.time_slot.length - 1; i++) {
          if (day.time_slot.is_delete == 1) {
            return true;

          } else {
            const currentCloseTime = new Date(`1970-01-01T${day.time_slot[i].close_time}`);
            const nextOpenTime = new Date(`1970-01-01T${day.time_slot[i + 1].open_time}`);
            const currentOpenTime = new Date(`1970-01-01T${day.time_slot[i].open_time}`)
            console.log("currentCloseTime====>", currentCloseTime)
            console.log("nextOpenTime====>", nextOpenTime)

            if (currentCloseTime > nextOpenTime) {
              AlertError(`Error: Close time (${day.time_slot[i].close_time}) overlaps with open time (${day.time_slot[i + 1].open_time}) on ${day.datename_en}`);
              return false; // Stop further validation if an error is found
            }

          }

        }
      }
    }
    return true; // Validation passed
  }



  useEffect(() => {
    console.log("lang=====>", lang)
    getShopOpenTime();
  }, []);
  useEffect(() => {
    // if (startTime.length > 0) {
    //   getWorkingTime();
    // }
    getWorkingTime();
  }, [startTime, endTime]);

  useEffect(() => {
    console.log("daydata update=======>:", daydata);
  }, [daydata]);
  useEffect(() => {
    const initialState = {};
    daydata.forEach((day) => {
      initialState[day.date_id] = [];
    });
    setAdditionalTimeRows(initialState);
  }, [daydata]);
  const getWorkingTime = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/deliveryslot?page=1&limit=10`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (Object.keys(res.data.data).length > 0) {
          let daydata = [];
          res.data.data.map((item) => {
            daydata.push({
              date_id: item.date_id,
              is_closed: item.is_closed,
              datename_en: item.days_datename_en,
              datename_th: item.days_datename_th,
              time_slot: item.time_slot,
            });
          });
          const updatedCheckedDays = { ...checkedDays };
          daydata.forEach(day => {
            if (day.is_closed === 1) {
              updatedCheckedDays[day.date_id] = false;
            }
          });
          setdaydata(daydata);
          setCheckedDays(updatedCheckedDays);
        } else {
          console.log("=========no data found ==========");
          // console.log("=======(startTime)======>",startTime);
          // console.log("=======(endTime)======>",endTime);

          let newArr = [];
          newArr = [
            {
              date_id: 1,
              is_closed: false,
              datename_en: "Monday",
              datename_th: "จันทร์",
              time_slot: [{
                id: null,
                date_id: 1,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 2,
              is_closed: false,
              datename_en: "TuesDay",
              datename_th: "อังคาร",
              time_slot: [{
                id: null,
                date_id: 2,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 3,
              is_closed: false,
              datename_en: "Wednesday",
              datename_th: "พุธ",
              time_slot: [{
                id: null,
                date_id: 3,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 4,
              is_closed: false,
              datename_en: "Thursday",
              datename_th: "พฤหัส",
              time_slot: [{
                id: null,
                date_id: 4,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 5,
              is_closed: false,
              datename_en: "Friday",
              datename_th: "ศุกร์",
              time_slot: [{
                id: null,
                date_id: 5,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 6,
              is_closed: false,
              datename_en: "Saturday",
              datename_th: "เสาร์",
              time_slot: [{
                id: null,
                date_id: 6,
                open_time: startTime,
                close_time: endTime,
              }],
            },
            {
              date_id: 7,
              is_closed: false,
              datename_en: "Friday",
              datename_th: "ศุกร์",
              time_slot: [{
                id: null,
                date_id: 7,
                open_time: startTime,
                close_time: endTime,
              }],
            },
          ];
          setdaydata(newArr);
        }
      })
      .catch((err) => { });
  };
  const getShopOpenTime = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/branch`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          console.log("=======(startTime)======>", res.data.data.open);
          console.log("=======(endTime)======>", res.data.data.close);
          setStartTime(res.data.data.open);
          setEndTime(res.data.data.close);
        }
      })
      .catch((err) => { });
  };
  console.log("=========>data", daydata)

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113330",
            defaultMessage: "Edit Delivery Hours",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div>
              {daydata.map((day, indexDay) => (
                <div key={day.date_id} className="form-group">
                  <div className="row">
                    {/* ----checkbox---- */}
                    <div className="col-md-4">
                      <label>
                        <input
                          className="mr-5"
                          type="checkbox"
                          checked={checkedDays[day.date_id]}
                          onChange={() => handleCheckboxChange(day.date_id)}
                        />
                        {lang == "en" ? (
                          <>{day.datename_en}</>
                        ) : (
                          <>{day.datename_th}</>
                        )}
                      </label>
                    </div>
                    {checkedDays[day.date_id] ? (
                      <div className="">
                        <div className="">
                          {day.time_slot.map(
                            (time, indexTime) =>
                              time.is_delete != 1 && (
                                <div
                                  className="d-flex flex-wrap"
                                  style={{ flex: "1 0 21%" }}
                                >
                                  {/* <div className=" mb-3">
                                    <input
                                      type="time"
                                      className="form-control"
                                      value={time.open_time}
                                      onChange={(e) =>
                                        handleTimeChange(
                                          indexDay,
                                          indexTime,
                                          day.date_id,
                                          e.target.value,
                                          "open_time"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="">
                                    <input
                                      type="time"
                                      className="form-control"
                                      value={time.close_time}
                                      onChange={(e) =>
                                        handleTimeChange(
                                          indexDay,
                                          indexTime,
                                          day.date_id,
                                          e.target.value,
                                          "close_time"
                                        )
                                      }
                                    />
                                  </div> */}
                                  <div
                                    className="d-flex align-items-center justify-content-between mb-3"
                                    style={{ gap: "10px", alignItems: "center" }}
                                  >

                                    <TimePicker
                                      value={time.open_time ? moment(time.open_time, "HH:mm") : null}
                                      onChange={(value) => handleTimeChange(indexDay, indexTime, day.date_id, value, "open_time")}
                                      format="HH:mm"
                                      use12Hours={false}
                                      showSecond={false}
                                      className="custom-time-picker" // ใช้ class สำหรับกรณีต้องการปรับเพิ่มใน CSS
                                      style={{
                                        border: "none", // ไม่มีเส้นขอบ
                                        padding: "0", // ลด padding ภายใน
                                        height: "35px", // ปรับความสูงตามต้องการ
                                        backgroundColor: "transparent", // พื้นหลังโปร่งใส
                                      }}
                                      clearIcon={<span style={{ display: "none" }} />} />
                                    <TimePicker
                                      value={time.close_time ? moment(time.close_time, "HH:mm") : null}
                                      onChange={(value) => handleTimeChange(indexDay, indexTime, day.date_id, value, "close_time")}
                                      format="HH:mm"
                                      use12Hours={false}
                                      showSecond={false}
                                      className="custom-time-picker" // ใช้ class สำหรับกรณีต้องการปรับเพิ่มใน CSS
                                      style={{
                                        border: "none", // ไม่มีเส้นขอบ
                                        padding: "0", // ลด padding ภายใน
                                        height: "35px", // ปรับความสูงตามต้องการ
                                        backgroundColor: "transparent", // พื้นหลังโปร่งใส
                                      }}
                                      clearIcon={<span style={{ display: "none" }} />} />

                                  </div>
                                  <div className="ml-3">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        handleDeleteRow(
                                          indexDay,
                                          indexTime,
                                          day.date_id
                                        )
                                      }
                                    >
                                      {intl.formatMessage({ id: "113011", defaultMessage: "Delete" })}
                                    </button>
                                  </div>
                                </div>
                              )
                          )}
                        </div>

                        {additionalTimeRows[indexDay] &&
                          additionalTimeRows[indexDay].map((row, rowIndex) => (
                            <div
                              key={rowIndex}
                              className="d-flex flex-wrap mb-3"
                              style={{ flex: "1 0 21%" }}
                            >
                              <div className="">
                                <input
                                  type="time"
                                  className="form-control"
                                  value={row.start_time}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day.date_id,
                                      e.target.value,
                                      "start_time",
                                      rowIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="">
                                <input
                                  type="time"
                                  className="form-control"
                                  value={row.end_time}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day.date_id,
                                      e.target.value,
                                      "end_time",
                                      rowIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="ml-3">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    handleDeleteRow(
                                      indexDay,
                                      day.time_slot.length + 1,
                                      day.date_id
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}
                        <div className="">
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                handleAddTime(
                                  indexDay,
                                  day.time_slot.length + 1,
                                  day.date_id
                                )
                              }
                            >
                              {intl.formatMessage({ id: "113449", defaultMessage: "Add Time Slot" })}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p
                        style={{
                          marginTop: "15px",
                          marginLeft: "15px",
                          float: "left",
                          width: "30%",
                        }}
                      >
                        ( {intl.formatMessage({ id: "109022", defaultMessage: "Closed" })} )

                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="d-flex justify-content-center"
              style={{
                marginBottom: "25px",
              }}
            >
              <Link to="/Shop">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "108011",
                    defaultMessage: "cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => onsubmit()}>
                {intl.formatMessage({ id: "108032", defaultMessage: "Save" })}
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
