import React, { useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import "bootstrap/dist/css/bootstrap.min.css";
import { styled } from "@material-ui/core/styles";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 30,
  padding: 0,
  display: "flex",
  alignItems: "center",
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#0D6EFD !important",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 22,
    backgroundColor: "#fff",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
    marginTop: 1,
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: "#DEE2E6 !important",
    opacity: 1,
    boxSizing: "border-box",
  },
}));

export const Step4Option = () => {
  const {
    checkedRedemption,
    setCheckedRedemption,
    checkedItemsLimitPerDay,
    setCheckedItemsLimitPerDay,
    checkedAutoApply,
    setCheckedAutoApply,
    selectedButton,
    setSelectedButton,
    redemptionDefault,
    setRedemptionDefault,
    itemsLimitPerDayDefault,
    setItemsLimitPerDayDefault,
    checkedIncludeOption,
    setCheckedIncludeOption,
    checkedRouding,
    setCheckedRounding,
    roundingDiscountValue,
    setRoundingDiscountValue,

  } = useCreatePromotionContext();

  const handleButtonClick = (num) => {
    setSelectedButton(num === selectedButton ? null : num);
  };
  const handleButtonRoundingClick = (num) => {
    setRoundingDiscountValue(num === roundingDiscountValue ? null : num);
  };

  useEffect(() => {
    console.log("roundingDiscountValue ->", roundingDiscountValue)
  }, [roundingDiscountValue])

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70px",
          borderBottom: "1px solid #EBEDF3",
          marginTop: "10px",
          padding: "2rem 0 2rem 0",
          backgroundColor: "#FFFFFF",
        }}
      >
        <strong className="promotion-sarabun-strong">ตัวเลือก</strong>
      </div>

      <div
        className="container px-10 py-6 border rounded"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <form>
          <div className="mt-7 row">
            <label className="col-md-3 mb-3 font-weight-bold promotion-sarabun-text">
              จำนวนใช้โปรโมชั่น
            </label>
            <div className="col-md-3 mb-3 font-weight-bold d-flex flex-column align-items-start">
              <CustomSwitch
                checked={checkedRedemption}
                onChange={(e) => setCheckedRedemption(e.target.checked)}
              />
              <input
                type="number"
                className="form-control mt-3"
                placeholder="1"
                min="1"
                value={redemptionDefault}
                onChange={(e) => {
                  const value = e.target.value;
                  setRedemptionDefault(value === "" ? "" : parseInt(value, 10));
                }}
                style={{ width: "100%", backgroundColor: "#F3F3F3" }}
              />
            </div>
          </div>

          <div className="row mt-7">
            <label className="col-md-3 mb-3 font-weight-bold promotion-sarabun-text">
              จำนวนใช้โปรโมชั่น/วัน
            </label>
            <div className="col-md-3 mb-3 font-weight-bold d-flex flex-column align-items-start">
              <CustomSwitch
                checked={checkedItemsLimitPerDay}
                onChange={(e) => setCheckedItemsLimitPerDay(e.target.checked)}
              />
              <input
                type="number"
                className="form-control mt-3"
                placeholder="1"
                min="1"
                value={itemsLimitPerDayDefault}
                onChange={(e) => {
                  const value = e.target.value;
                  setItemsLimitPerDayDefault(
                    value === "" ? "" : parseInt(value, 10)
                  );
                }}
                style={{ width: "100%", backgroundColor: "#F3F3F3" }}
              />
            </div>
          </div>

          <div className="row mt-7">
            <label className="col-md-3 font-weight-bold promotion-sarabun-text">
              โปรโมชั่นอัตโนมัติ
            </label>
            <div className="col-md-3 d-flex flex-column align-items-start">
              <CustomSwitch
                checked={checkedAutoApply}
                onChange={(e) => {
                  setCheckedAutoApply(e.target.checked);
                  setSelectedButton(e.target.checked ? 1 : 0);
                }}
              />
              {checkedAutoApply && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <div
                      key={num}
                      style={{ textAlign: "center", margin: "0 8px" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleButtonClick(num)}
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                          border: "2px solid #DADEEA",
                          backgroundColor:
                            selectedButton === num ? "#3b82f6" : "transparent",
                          color: selectedButton === num ? "#fff" : "#333",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {num}
                      </button>
                      {num === 1 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>High</p>
                      )}
                      {num === 3 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>Medium</p>
                      )}
                      {num === 5 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>Low</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="mt-7 row">
            <label className="col-md-3 mb-3 font-weight-bold promotion-sarabun-text">
              รวม option
            </label>
            <div className="col-md-3 mb-3 font-weight-bold d-flex flex-column align-items-start">
              <CustomSwitch
                checked={checkedIncludeOption}
                onChange={(e) => setCheckedIncludeOption(e.target.checked)}
              />
            </div>
          </div>

          <div className="row mt-7">
            <label className="col-md-3 font-weight-bold promotion-sarabun-text">
              การปัดเศษราคาหลังใช้ส่วนลด
              {checkedRouding && (
                <div style={{ marginTop: "10px", fontWeight: "normal", fontSize: "12px", lineHeight: "15px", paddingRight: "10px" }}>
                  <p >
                    ถ้าส่วนลดมีจุดทศนิยมมากกว่าหรือเท่ากับตัวเลขที่เลือก จะปัดขึ้น ถ้าน้อยกว่าตัวเลขที่เลือก จะปัดลง
                  </p>
                  <p>
                    ตัวอย่าง: เลือก 0.5 ถ้าส่วนลดมีมูลค่า 15.6บาท จะปัดเป็น16บาทสำหรับสินค้านั้น ถ้าส่วนลดมีมูลค่า 15.48บาท จะปัดเป็น14บาท
                  </p>
                </div>
              )}
            </label>

            <div className="col-md-3 d-flex flex-column align-items-start">
              <CustomSwitch
                checked={checkedRouding}
                onChange={(e) => {
                  setCheckedRounding(e.target.checked);
                  setRoundingDiscountValue(e.target.checked ? 1 : 0);
                }}
              />
              {checkedRouding && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  {[1, 0.5, 0.25, 0.10, 0.05].map((num) => (
                    <div
                      key={num}
                      style={{ textAlign: "center", margin: "0 8px" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleButtonRoundingClick(num)}
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                          border: "2px solid #DADEEA",
                          backgroundColor:
                            roundingDiscountValue === num ? "#3b82f6" : "transparent",
                          color: roundingDiscountValue === num ? "#fff" : "#333",
                          fontSize: "14px",
                          // fontWeight: "bold",
                          cursor: "pointer",
                          padding: "0px",

                        }}
                      >
                        {num}
                      </button>
                      {/* {num === 1 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>High</p>
                      )}
                      {num === 3 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>Medium</p>
                      )}
                      {num === 5 && (
                        <p style={{ margin: "5px 0px 0px 0px" }}>Low</p>
                      )} */}

                    </div>
                  ))}
                </div>
              )}

            </div>

          </div>
        </form>
      </div>
    </>
  );
};
