import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const EditInventory = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113132", defaultMessage: "Inventory" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [test, setTest] = useState([]);
  const [titleEN, setTitleEN] = useState("");
  const [role, setRole] = useState("");
  const [listUnit, setListUnit] = useState([]);
  const [listMenu, setListMenu] = useState([]);
  const [listMaterial, setListMaterial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [menuID, setMenuID] = useState(null)
  const [menu, setMenu] = useState(null)
  const { user, authToken, accessTokenPrivilege } = useSelector(
    (state) => state.auth
  );
  const [listGroup2, setListGroup2] = useState([]);
  const [typeList, setTypeList] = useState([
    "VIEW",
    "CREATE",
    "EDIT",
    "DELETE",
    "IMPORT",
    "EXPORT",
    "ADMIN",
  ]);
  const [listUnitConvert, setListUnitConvert] = useState([]);

  const setMapData = (data) => {
    let temp = [];
    setData(data);
    setTest(data);
  };

  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material?page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMaterial(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListMenu = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/menu?limit=10&page=1`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMenu(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListMenuSearch = async (data) => {
    let str = `&${`search=${data}`}`
    if(!data) {
      str = ''
    }
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/menu?limit=10&page=1${str}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMenu(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnit(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getListUnitConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit_convert?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnitConvert(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const updateFieldChanged = (index, value, name) => {
    let newArr = [...data]; // copying the old datas array
    newArr[index][name] = value; // replace e.target.value with whatever you want to change it to
    setData(newArr);
  };

  
  useEffect(() => {
    setData(prev => prev)
    setData(data)
    console.log('re-render', data);
    setIsLoading(false)
  }, [data]);
  
  const updateSelectUnitAndUnitConvert = (index, value) => {
    setIsLoading(true)
    console.log('updateSelectUnitAndUnitConvert : ', value);
    console.log('listMaterial : ', listMaterial);

    let material = value
    
    let main = listUnit.filter(x => x.id == value.material_unit_id)
    console.log('updateSelectUnitAndUnitConvert main', main[0])
    // setselectUnitID(main[0])
    let sub = listUnitConvert.filter(x => x.id == value.unit_convert_id)
    // console.log('updateSelectUnitAndUnitConvert sub', sub[0])
    console.log('updateSelectUnitAndUnitConvert data', data)
    let newArr = [...data]; 
    newArr[index]['unit_id'] = value.material_unit_id;
    newArr[index]['unit_convert_id'] = value.unit_convert_id;
    newArr[index]['material'] = material;
    newArr[index]['unit'] = main[0];
    newArr[index]['unit_convert'] = sub[0];
    setData(newArr);
    
    console.log('updateSelectUnitAndUnitConvert updateFieldChanged', newArr)
  }


  useEffect(() => {
    getListMaterial();
    getListMenu();
    getListConvert()
    setProps()
    getListUnitConvert()
    // get Material
    // get หน่วย
    // อาจมี get ร้าน
    // get menu
    
  }, []);

  const setProps =()=> {
    console.log(props)
    setMenuID(props.location.state.row.menu_id)
    setMenu(props.location.state.row.posItem)
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/bom/${props.location.state.row.menu_id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          let temp = []
          res.data.data.map((item, index)=>{
            console.log('setProps', item, listMaterial.filter(x=>x.id == item.material_id))
            temp.push(
              {
                material_id: item.material_id,
                unit_convert_id: item.unit_convert_id,
                unit_id: item.unit_id,
                material_qty: item.material_qty,
                material: item.bomMaterial,
                unit: item.bomMaterialUnit,
                unit_convert: item.bomUnitConvert,
              }
            );
          })
          setData(temp)
          console.log(temp)
        }
      })
      .catch((err) => {});
    
  }

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'

  const onSubmit = async () => {
    console.log("data", data);
    // return false;
    let shop_id = localStorage.getItem('shop_id')
    let branch_id = localStorage.getItem('branch_id')
    let temp = [];
    for (let i in data) {
      if (data[i].material_id == undefined || data[i].material_id == null) {
        return AlertError(
          `Please insert material at ${Number(i) + 1}`
        );
      }
      // if (data[i].unit_convert_id == undefined || data[i].unit_convert_id == null) {
      //   return AlertError(
      //     `Please insert unit convert at ${Number(i) + 1}`
      //   );
      // }
      if (data[i].material_qty == [] || data[i].material_qty == 0) {
        return AlertError(
          `Please insert material quantity at ${Number(i) + 1}`
        );
      }
      temp.push({
        material_id: data[i].material_id,
        unit_convert_id: data[i].unit_convert_id,
        unit_id: data[i].unit_id,
        material_qty: data[i].material_qty,
      });
      console.log(temp);
    }
    /*let temp_data = [{
        material_id: 1,
        unit_convert_id: 1,
        material_qty: 10
      }]*/
      var events = {
        menu_id: menuID,
        material_detail: temp,
        shop_id: shop_id,
        branch_id: branch_id,
      };
    let apiUrls = `${baseURL}/management/stock/bom/${props.location.state.row.menu_id}`;
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "put",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp = {
      material_id: null,
      unit_convert_id: null,
      material_qty: null,
      material: {},
      unit: {},
    };
    setData((data) => [...data, temp]);
  };

  const DeleteQuestion = (index) => {
    var array = [...data]; // make a separate copy of the array
    array.splice(index, 1);
    setData(array);
  };

  if(isLoading){
    return <p>Loading...</p>
  }

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113204",
            defaultMessage: "Edit Inventory",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {data.length == 0 ? (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => {
                addPermission();
              }}
            >
              {intl.formatMessage({ id: "113273", defaultMessage: "ADD" })}
            </button>
          ) : null}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113196",
                defaultMessage: "Select Menu",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={listMenu}
              getOptionLabel={(option) => option.item_name}
              onInputChange={(event, newInputValue) => {
                getListMenuSearch(newInputValue)
                
            }}
              getOptionSelected={(option, value) => option.id == value.id}
              value={menu}
              onChange={(event, newValue) => {
                console.log(newValue);
                setMenuID(newValue.id)
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          {data.map((item, index) => (
            <Card className="Small shadow" key={index}>
              <CardHeader title={index + 1}></CardHeader>
              <CardBody>
                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    {intl.formatMessage({
                      id: "113197",
                      defaultMessage: "Select Material",
                    })}
                    <span style={{ color: "red" }}>*</span>:{" "}
                  </p>
                  <Autocomplete
                    id="grouped-demo"
                    options={listMaterial}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id == value.id}
                    value={item.material}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      updateFieldChanged(index, newValue.id, "material_id");
                      updateFieldChanged(index, newValue, "material");
                      //updateFieldChanged(index, newValue.id, "page_id");*/
                      updateSelectUnitAndUnitConvert(index, newValue)
                    }}
                    style={{
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </div>

                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    <span style={{ color: "red" }}></span>
                    {intl.formatMessage({
                      id: "113221",
                      defaultMessage: "Quantity",
                    })}
                    <span style={{ color: "red" }}>*</span>:{" "}
                  </p>
                  <input
                    name="modelGroup"
                    type="number"
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={item.material_qty}
                    onChange={(e) => {
                      updateFieldChanged(index, e.target.value, "material_qty");
                    }}
                  />
                </div>

                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    {intl.formatMessage({
                      id: "113199",
                      defaultMessage: "Select Unit",
                    })}
                    <span style={{ color: "red" }}>*</span>:{" "}
                  </p>
                  <Autocomplete
                    id="grouped-demo"
                    options={listUnit}
                    
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option == value}
                    value={item.unit}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      updateFieldChanged(index, newValue, "unit");
                      updateFieldChanged(index, newValue.id, "unit_id");
                    }}
                    style={{
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    // disabled
                  />
                </div>

                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    {intl.formatMessage({
                      id: "113233",
                      defaultMessage: "Select Convert Unit",
                    })}
                    <span style={{ color: "red" }}>*</span>:{" "}
                  </p>
                  <Autocomplete
                    id="grouped-demo"
                    options={listUnitConvert}
                    
                    getOptionLabel={(option) => `${option.main_unit ? option.main_unit.name : '-' }-${option.sub_unit ? option.sub_unit.name : '-' }`}
                    value={item.unit_convert}
                    getOptionSelected={(option, value) => option == value}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      updateFieldChanged(index, newValue, "unit_convert");
                      updateFieldChanged(index, newValue.id, "unit_convert_id");
                    }}
                    style={{
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    // disabled
                  />
                </div>


                <CardFooter>
                  <CardHeaderToolbar>
                    <button
                      type="button"
                      className="btn btn-danger mr-2"
                      onClick={() => {
                        DeleteQuestion(index);
                      }}
                    >
                      {intl.formatMessage({
                        id: "113011",
                        defaultMessage: "DELETE",
                      })}
                    </button>

                    {index == data.length - 1 ? (
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={() => {
                          addPermission();
                        }}
                      >
                        {intl.formatMessage({
                          id: "113273",
                          defaultMessage: "ADD",
                        })}
                      </button>
                    ) : null}
                  </CardHeaderToolbar>
                </CardFooter>
              </CardBody>
            </Card>
          ))}

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/Inventory">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
