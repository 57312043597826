import { Photo } from "@material-ui/icons";
import { baseURL } from "../../../service/API.js";
import { temp_store } from "../../../modules/function/temp_store";
const React = require("react");
const { useState, useEffect } = React;
const axios = require("axios");
const moment = require("moment");
require("bootstrap/dist/css/bootstrap.min.css");
const { makeStyles } = require("@material-ui/core/styles");
const { useSelector } = require("react-redux");

const useStyles = makeStyles({
  customBadge: {
    border: "1px solid #DADEEA",
    padding: "12px 16px",
    borderRadius: "23px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f9fa",
    fontFamily: "Sarabun, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.2px",
    color: "#333",
  },
});

export const ViewPromotionV2 = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const { authToken } = useSelector((state) => state.auth);

  const [promoNameTh, setPromoNameTh] = useState("");
  const [promoNameEn, setPromoNameEn] = useState("");
  const [promoDescTh, setPromoDescTh] = useState("");
  const [promoDescEn, setPromoDescEn] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [expiryDateTime, setExpiryDateTime] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [amountValue, setAmountValue] = useState("");
  const [listData, setListData] = useState([]);
  const [promoTargetTh, setPromoTargetTh] = useState("");
  const [promoTargetEn, setPromoTargetEn] = useState("");
  const [promoImg, setPromoImg] = useState([]);
  const [purchaseOption, setPurchaseOption] = useState([]);
  const [promotionData, setPromotionData] = useState("");
  const [isDineIn, setIsDineIn] = useState("");
  const [isTakeAway, setIsTakeAway] = useState("");
  const [isPickUp, setIsPickUp] = useState("");
  const [isTimeRange, setIsTimeRange] = useState("");
  const [isDayRange, setIsDayRange] = useState("");
  const [checkedIncludeOption, setCheckedIncludeOption] = useState(false);
  const [type, setType] = useState("");
  const [targetQuantity, setTargetQuantity] = useState("");
  const [isAutoPromotion, setIsAutoPromotion] = useState("");
  const [promotionImportanceText, setPromotionImportanceText] = useState("");
  const [promotionType, setPromotionType] = useState(""); //1,2,14
  const [discountTypeId, setDiscountTypeId] = useState(""); // 3,4,15
  const [discountTargetId, setDiscountTargetId] = useState(""); //5,6,12
  const [conditionListType, setConditionListType] = useState(""); //ประเภทของสินค้าที่จะแถม
  const [usageLimitPerDay, setUsageLimitPerDay] = useState(null);
  const [redemptionLimit, setRedemptionLimit] = useState(null);
  const [bundleTypeId, setBundleTypeId] = useState(null);
  const [checkedRouding, setCheckedRounding] = useState(false);
  const [roundingDiscountValue, setRoundingDiscountValue] = useState(0);
  useEffect(() => {
    const fetchPromotionText = () => {
      const text = promotionAutoApply();
      setPromotionImportanceText(text);
    };

    fetchPromotionText();
  }, [isAutoPromotion]);
  // useEffect(() => {
  //   gettingData();
  //   getPromotionData();
  // }, []);
  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true); // เริ่มแสดง loading
      try {
        await Promise.all([gettingData(), getPromotionData()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // ซ่อน loading เมื่อโหลดเสร็จ
      }
    };

    fetchAllData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${baseURL}/management/promotionnew2/${
          temp_store(props).location.state.row.id
        }`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      const dataResponse = response.data.data[0];
      // console.log("dataResponse -->", dataResponse);
      setPromoNameTh(dataResponse.promotion_name_th);
      setPromoNameEn(dataResponse.promotion_name_en);
      setPromoDescTh(dataResponse.promotion_desc_th);
      setPromoDescEn(dataResponse.promotion_desc_en);
      setStartDateTime(
        moment(dataResponse.start_date_time).format("DD MMM YYYY hh:mm:ss")
      );
      setExpiryDateTime(
        moment(dataResponse.expiry_date_time).format("DD MMM YYYY hh:mm:ss")
      );
      setDiscountPercent(dataResponse.discount_percent);
      setDiscountValue(dataResponse.discount_value);
      setAmountValue(dataResponse.amount_value);
      setPromoImg(dataResponse.promo_img_path[0].img_link);
      setIsDineIn(dataResponse.is_dinein);
      setIsPickUp(dataResponse.is_pickup);
      setIsTakeAway(dataResponse.is_take_away);
      setIsTimeRange(dataResponse.is_time_range);
      setIsDayRange(dataResponse.is_day_range);
      setCheckedRounding(dataResponse.is_rounding_discount);
      setRoundingDiscountValue(dataResponse.rounding_discount_value);
      setType(
        dataResponse.promotion_type === "PUBLIC"
          ? "ลูกค้ามองเห็นโปรโมชั่นนี้"
          : "พนักงานต้องกดใช้โปรโมชั่นให้ลูกค้า"
      );
      setTargetQuantity(dataResponse.target_quantity);
      setIsAutoPromotion(dataResponse.is_auto);
      setPromotionType(dataResponse.promotion_type_id); // Discount By, Bundle Offer, Buy Item get item // 1,2,14
      setDiscountTypeId(dataResponse.discount_type[0].id); //ประเภทของส่วนลด fixed, percent, free // 3,4,15
      setDiscountTargetId(dataResponse.discount_target[0].id); // ไอเทมที่แถมหรือลด เป็นitem or category หรือเป้นส่วนลดแบบทั้งบิลเมื่อซื้อขั้นต่ำ// 5 or 6 or 12
      setCheckedIncludeOption(dataResponse.is_include_option);
      setRedemptionLimit(dataResponse.redemption_limit);
      setUsageLimitPerDay(dataResponse.usage_limit_per_day);
      if (dataResponse.bundle_type_id && dataResponse.bundle_type_id[0]?.id) {
        setBundleTypeId(dataResponse.bundle_type_id[0].id);
      } else {
        setBundleTypeId(null); 
      }
      if (dataResponse.promotion_target_item.item.length > 0) {
        setConditionListType(dataResponse.promotion_target_item.item[0].type);
      } else if (dataResponse.promotion_target_item.group_item.length > 0) {
        setConditionListType(
          dataResponse.promotion_target_item.group_item[0].type
        );
      }
      let items = [];
      if (
        dataResponse.promotion_item_list &&
        dataResponse.promotion_item_list.length > 0
      ) {
        items = dataResponse.promotion_item_list.map((item) => {
          const {
            discount_value,
            discount_percent,
            amount_value,
          } = dataResponse;
          return {
            item_id: item.id,
            item_name: item.item_name,
            item_name_en: item.item_name_en,
            item_price: item.price,
            item_img:
              item.item_image_path || "./media/icon/picture-default.png",
            amount_value: amount_value,
          };
        });
      } else if (
        dataResponse.promotion_group_list &&
        dataResponse.promotion_group_list.length > 0
      ) {
        items = dataResponse.promotion_group_list.map((groupItem) => {
          return {
            group_id: groupItem.id,
            group_name: groupItem.group_name,
            group_name_en: groupItem.group_name_en,
            group_img: groupItem.image || null,
            amount_value: "",
          };
        });
      }

      setListData(items);
    } catch (error) {
      console.error("Error fetching promotion data:", error);
    }
  };
  console.log("checkedRouding ->", checkedRouding);
  console.log("roundingDiscountValue ->", roundingDiscountValue);
  const getPromotionData = async () => {
    try {
      console.log("Row ID:", temp_store(props).location.state.row.id);
      const response = await axios({
        method: "get",
        url: `${baseURL}/management/promotionnew2/${
          temp_store(props).location.state.row.id
        }`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Row ID:", temp_store(props).location.state.row.id);
      const targetItems = response.data.data[0].promotion_target_item;
      if (targetItems) {
        let promotionTargetItemData = {};

        if (targetItems.item && targetItems.item.length > 0) {
          promotionTargetItemData = {
            type: "item",
            data: targetItems.item.map((item) => ({
              id: item.id,
              item_name: item.item_name,
              item_name_en: item.item_name_en,
              item_img: item.first_img || "./media/icon/picture-default.png",
              item_price: item.price,
            })),
          };
        } else if (
          targetItems.group_item &&
          targetItems.group_item.length > 0
        ) {
          promotionTargetItemData = {
            type: "group_item",
            data: targetItems.group_item.map((groupItem) => ({
              id: groupItem.id,
              group_name: groupItem.group_name,
              group_name_en: groupItem.group_name_en,
              group_name_en: groupItem.group_name_en,
              group_img: groupItem.first_img || "defaultImagePath.jpg",
            })),
          };
        }
        setPromotionData(promotionTargetItemData);
      }
    } catch (error) {
      console.error("Error fetching promotion data:", error);
    }
  };
  const promotionAutoApply = () => {
    switch (isAutoPromotion) {
      case 2:
        return "2 (ค่อนข้างสำคัญ)";
      case 3:
        return "3 (สำคัญปานกลาง)";
      case 4:
        return "4 (สำคัญค่อนข้างต่ำ)";
      case 5:
        return "5 (ไม่สำคัญ)";
      default:
        return "1 (สำคัญที่สุด)";
    }
  };

  const getDiscountMessage = () => {
    if (discountTypeId === 3 && discountTargetId !== 12) {
      // fix
      return `รับส่วนลด ${discountValue} บาท จาก${
        discountTargetId === 5 ? "รายการสินค้า" : "หมวดหมู่"
      }เหล่านี้`;
    } else if (discountTypeId === 4 && discountTargetId !== 12) {
      // %
      return `รับส่วนลด ${discountPercent}% จาก${
        discountTargetId === 5 ? "รายการสินค้า" : "หมวดหมู่"
      }เหล่านี้`;
    } else if (discountTargetId === 12) {
      // bill
      if (discountTypeId === 3) {
        //fix
        return `ซื้อสินค้าขั้นต่ำ ${amountValue} บาท เพื่อรับส่วนลด ${discountValue} บาท จากยอดบิล`;
      } else if (discountTypeId === 4) {
        //%
        return `ซื้อสินค้าขั้นต่ำ ${amountValue} บาท เพื่อรับส่วนลด ${discountPercent} % จากยอดบิล`;
      }
    }
  };

  return isLoading ?
    <div className="d-flex justify-content-center align-items-center top-0 left-0 w-100 h-100 bg-white bg-opacity-75">
      < div className="d-flex align-items-center" >
        <div className="mr-2 text-muted">Loading...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div >
    </div > :
    <div
      className="container p-6 border rounded"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div className="d-flex justify-center align-items-center p-4 rounded">
        <h5 style={{ fontSize: "22px", fontWeight: 600 }}>
          Promotion Details{" "}
        </h5>
      </div>

      <hr
        style={{ width: "100%", border: "1px solid #EBEDF3", margin: "10px 0" }}
      />

      <div className="d-flex flex-column align-items-center ">
        {promoImg ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "240px",
              height: "200px",
              cursor: "pointer",
            }}
          >
            <img
              src={promoImg}
              alt="Promotion"
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          <div
            className="bg-light d-flex flex-column justify-content-center align-items-center"
            style={{
              border: "1px dashed #ccc",
              borderWidth: "2px",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
              width: "240px",
              height: "240px",
              cursor: "pointer",
            }}
          >
            <Photo
              style={{
                fontSize: 70,
                color: "#aaa",
                paddingTop: "10px",
              }}
            />
            <p>ไม่มีรูปภาพ</p>
          </div>
        )}

        <h6
          style={{ fontWeight: 600, fontSize: "1.25rem", textAlign: "center" }}
          className="mt-6"
        >
          <span>
            {promoNameTh} {promoNameEn}
          </span>
        </h6>
        <p style={{ fontSize: "16px", color: "#71717A", borderRadius: "4px" }}>
          {promoDescTh || promoDescEn}
        </p>
        <p style={{ fontSize: "16px", borderRadius: "4px" }}>
          โปรโมชั่นสามารถใช้ได้:{" "}
          {redemptionLimit == null
            ? `ไม่จำกัดครั้ง`
            : `${redemptionLimit} ครั้ง`}
        </p>
        <p style={{ fontSize: "16px", borderRadius: "4px" }}>
          {usageLimitPerDay !== null &&
            `ในหนึ่งวัน สามารถใช้โปรโมชั่นได้: ${usageLimitPerDay} ครั้ง`}
        </p>

        <div className="promotion-view-tags">
          <span className={`badge mx-2 ${classes.customBadge}`}>{type} </span>
          <span className={`badge mx-2 ${classes.customBadge}`}>
            {isAutoPromotion
              ? `ความสำคัญของโปรโมชั่น ${promotionImportanceText}`
              : "โปรโมชั่นจะไม่ถูกใช้งานโดยอัตโนมัติ"}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {startDateTime} - {expiryDateTime}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {isTimeRange ? `เวลาใช้งาน: ${isTimeRange}` : null}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {isDayRange ? `วันที่ใช้งานได้: ${isDayRange}` : null}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {checkedIncludeOption ? "รวมตัวเลือก" : "ไม่รวมตัวเลือก"}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {checkedRouding ? (roundingDiscountValue == 1 ? 'ทศนิยมของส่วนลดจะถูกปัดลงเสมอ' : `ถ้าส่วนลดมีทศนิยมเท่ากับหรือมากกว่า${roundingDiscountValue} เศษจะถูกปัดขึ้น ถ้าน้อยกว่าจะถูกปัดลง`) : "ราคาสินค้าไม่ถูกปัดเศษ"}
          </span>
        </div>

        <div className="d-flex justify-content-center my-3">
          {isDineIn == true && (
            <div className="text-center mx-3">
              <img
                src="/media/mockup/Step5/ทานที่ร้าน.jpg"
                alt="Dine In"
                style={{ width: "46px", margin: "8px" }}
              />
              <p className="m-0">ทานที่ร้าน</p>
            </div>
          )}
          {isPickUp == true && (
            <div className="text-center mx-3">
              <img
                src="/media/mockup/Step5/รับที่ร้าน.jpg"
                alt="Pick Up"
                style={{ width: "46px", margin: "8px" }}
              />
              <p className="m-0">รับที่ร้าน</p>
            </div>
          )}
          {isTakeAway == true && (
            <div className="text-center mx-3">
              <img
                src="/media/mockup/Step5/บริการจัดส่ง.jpg"
                alt="Delivery"
                style={{ width: "46px", margin: "8px" }}
              />
              <p className="m-0">บริการจัดส่ง</p>
            </div>
          )}
        </div>

        <hr
          style={{
            border: "none",
            borderTop: "1px dashed #DADEEA",
            width: "100%",
            margin: "1rem 0",
          }}
        />

        <div
          className="w-100 d-flex flex-column align-items-start"
          style={{ marginBottom: "10px" }}
        >
          {/* flow for discount -> 1 disc, 2 bundle, 14 itemitem */}
          {promotionType == 1 && (
            <strong
              style={{
                fontSize: "17px",
                marginBottom: "10px",
                color: "#3F4254",
              }}
            >
              {getDiscountMessage()}
            </strong>
          )}
          {promotionType == 2 && (
            <strong
              style={{
                fontSize: "17px",
                marginBottom: "10px",
                color: "#3F4254",
              }}
            >
              {getDiscountMessage()}
              {bundleTypeId === 8 && (
                <span
                  className="mt-8 mb-3 d-inline"
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  {` เมื่อซื้อสินค้าตาม${discountTargetId == 5 ? `รายการ` : `หมวดหมู่`
                    }ด้านล่างซ้ำกันครบ ${targetQuantity} ชิ้น`}
                </span>
              )}
              {bundleTypeId === 9 && (
                <span
                  className="mt-8 mb-3 d-inline"
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  {` เมื่อซื้อภายใน${discountTargetId == 5 ? `รายการ` : `หมวดหมู่`
                    }ด้านล่างครบ ${targetQuantity} ชิ้นแบบคละกัน (ต้องไม่ซ้ำรายการเดิม)`}
                </span>
              )}
            </strong>
          )}
          {promotionType == 14 && (
            <strong
              style={{
                fontSize: "17px",
                marginBottom: "10px",
                color: "#3F4254",
              }}
            >
              {discountTargetId == 5
                ? `ซื้อสินค้าจำนวน ${amountValue} ชิ้นจากรายการสินค้าเหล่านี้`
                : `ซื้อสินค้าจำนวน ${amountValue} ชิ้นจากหมวดหมู่เหล่านี้`}
            </strong>
          )}

          <div
            className="w-100 d-flex flex-wrap align-items-start"
            style={{ gap: "10px" }}
          >
            {listData &&
              listData.map((item, index) => {
                // คำนวณราคาส่วนลดแบบ Fix และแบบ %
                const discountedPriceFix = Math.max(
                  item.item_price - discountValue,
                  0
                );
                const discountedPricePercent = Math.max(
                  (item.item_price * (100 - discountPercent)) / 100,
                  0
                );

                return (
                  <div
                    key={index}
                    className="d-flex align-items-center p-2 rounded"
                    style={{
                      width: "364px",
                      height: "71px",
                      border: "1px solid #DADEEA",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {item.item_img && (
                      <img
                        src={
                          item.item_img ||
                          item.group_img
                        }
                        alt={
                          item.item_name || item.group_name || "Promotion Item"
                        }
                        style={{
                          width: "43px",
                          height: "43px",
                          margin: "14px",
                          borderRadius: "8px",
                        }}
                      />
                    )}


                    {/* ข้อมูลสินค้า */}
                    <div style={{ flex: 1 }}>
                      <strong
                        style={{
                          fontFamily: "Sarabun, sans-serif",
                          fontSize: "15px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          color: "#3F4254",
                        }}
                      >
                        {item.item_name || item.group_name}
                      </strong>

                      {/* ถ้าแสดงราคา */}
                      {discountTargetId === 5 && (promotionType === 1 || promotionType === 2) ? (
                        <>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "13px",
                              color: "#71717A",
                              marginTop: "5px",
                              marginLeft: "5px",
                              textDecoration: "line-through",
                            }}
                          >
                            {item.item_price
                              ? `${item.item_price} THB`
                              : "0 THB"}
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              margin: "0",
                              color: "red",
                              marginLeft: "5px",
                            }}
                          >
                            {discountTypeId === 3
                              ? `${discountedPriceFix.toFixed(2)} THB`
                              : `${discountedPricePercent.toFixed(2)} THB`}
                          </p>
                        </>
                      ) : (
                        discountTargetId === 5 && (
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "13px",
                              color: "#71717A",
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            {item.item_price !== undefined
                              ? `${item.item_price} THB`
                              : "0 THB"}
                          </p>

                        )
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

        </div>
      </div>

      {promotionType == 14 && (
        <div
          className="w-100 d-flex flex-column align-items-start"
          style={{ marginBottom: "10px" }}
        >
          <strong
            className="mt-4 mb-3 d-block"
            style={{ fontSize: "17px", marginBottom: "10px", color: "#3F4254" }}
          >
            {discountTypeId === 15 &&
              `รับฟรี สินค้า ${targetQuantity} ชิ้น จาก${promotionData.type === "item" ? "รายการ" : "หมวดหมู่"
              }เหล่านี้`}
            {discountTypeId === 3 &&
              `รับส่วนลด ${discountValue} บาท จาก${promotionData.type === "item" ? "สินค้า" : "หมวดหมู่"
              }เหล่านี้`}
            {discountTypeId === 4 &&
              `รับส่วนลด ${discountPercent} % จาก${promotionData.type === "item"
                ? "สินค้าเหล่านี้"
                : "หมวดหมู่เหล่านี้"
              }`}
          </strong>

          <div
            className="w-100 d-flex flex-wrap align-items-start"
            style={{ gap: "10px" }}
          >
            {promotionData.data &&
              promotionData.data.map((target, index) => {
                // คำนวณราคาส่วนลด
                const discountedPrice3 = Math.max(
                  target.item_price - discountValue,
                  0
                );
                const discountedPrice4 = Math.max(
                  (target.item_price * (100 - discountPercent)) / 100,
                  0
                );

                return (
                  <div
                    key={index}
                    className="d-flex align-items-center p-2 rounded"
                    style={{
                      width: "364px",
                      height: "71px",
                      border: "1px solid #DADEEA",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {conditionListType === "ITEM" && (
                      <img
                        src={
                          target.item_img ||
                          target.group_img ||
                          "./media/icon/picture-default.png"
                        }
                        alt={
                          target.item_name ||
                          target.group_name ||
                          "Promotion Item"
                        }
                        style={{
                          width: "43px",
                          height: "43px",
                          margin: "14px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    <div>
                      <div style={{ flex: 1 }}>
                        <strong
                          style={{
                            fontFamily: "Sarabun, sans-serif",
                            fontSize: "15px",
                            fontWeight: 600,
                            marginLeft: "5px",
                            color: "#3F4254",
                          }}
                        >
                          {target.item_name || target.group_name}
                        </strong>

                        {promotionData.type === "item" &&
                          discountTypeId === 15 && (
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                margin: "0",
                                color: "#71717A",
                              }}
                            >
                              0 THB (Free)
                            </p>
                          )}

                        {promotionData.type === "item" &&
                          (discountTypeId === 3 || discountTypeId === 4) && ( // ถ้าเป็นไอเท็ม ก็ลดไป(มีเส้นมาขีดฆ่า)
                            <>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#71717A",
                                  marginTop: "5px",
                                  textDecoration: "line-through",
                                }}
                              >
                                {target.item_price
                                  ? `${target.item_price} THB`
                                  : "0 THB"}
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  margin: "0",
                                  color: "red",
                                }}
                              >
                                {discountTypeId === 3
                                  ? `${discountedPrice3.toFixed(2)} THB` // ราคาหลังลดแบบ Fix
                                  : `${discountedPrice4.toFixed(2)} THB` // ราคาหลังลดแบบ%
                                }
                              </p>
                            </>
                          )}

                        {promotionData.type === "group_item" && (
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "13px",
                              color: "#71717A",
                              marginTop: "5px",
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
};
